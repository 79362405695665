import {EventEmitter, Injectable, Output} from '@angular/core';
import {Observable, of} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {tap} from 'rxjs/operators';
import {RecycleRule, RecycleRuleSet} from '../models/recycle-rule.model';
import {UpdateRecycleRuleRequest} from '../models/update-recycle-rule-request.model';


@Injectable({
    providedIn: 'root'
})
export class RecycleRuleService {

    private resourceUrl = '/api/processor/rules';

    /**
     * Arrays of Recycle Rules indexed by Processor Id
     */
    public recycleRuleSet: RecycleRuleSet = {};

    /**
     * Observable for any current fetches so we can return same observable if multiple requests fire
     * for the same resource
     */
    public requestObservable: Array<Observable<Array<RecycleRule>>> = [];

    /**
     * Stores timestamp of last processors refresh
     */
    public cacheTimestamp = {};

    /**
     * Cache lifetime in seconds
     * @type {number}
     */
    private cacheLife = 3600;

    /**
     * Notification to Array<RecycleRule> subscribers of note save events
     * @type {EventEmitter<boolean>}
     */
    @Output() onDataUpdateEvent = new EventEmitter<Array<RecycleRule>>();


    constructor(private http: HttpClient) {

    }

    update(updateRecycleRuleRequest: UpdateRecycleRuleRequest) {
        const headers = new HttpHeaders()
            .set("Content-Type", "application/json");
        return this.http.post(this.resourceUrl, updateRecycleRuleRequest, {headers}).pipe(
            tap(
                (recycleRules: Array<RecycleRule>) => {
                    // Refresh processor rule list
                    this.recycleRuleSet[updateRecycleRuleRequest.processorId] = recycleRules;
                    this.cacheTimestamp[updateRecycleRuleRequest.processorId] = new Date();
                    this.onDataUpdateEvent.emit(recycleRules);
                })
        );
    }


    /**
     * Return cached data, or request from server
     * @param force
     */
    get(processorId: number, force?: boolean): Observable<Array<RecycleRule>> {

        // If force is true, remove roles from cache
        if (force === true || this.isExpired(processorId)) {
            return this.request(processorId);

        } else {
            console.log(this.constructor.name + ": from cache ");   //TODO: REMOVE ME
            return of(this.recycleRuleSet[processorId]);    // return cached processors
        }
    }


    /**
     * Perform Request to server
     */
    private request(processorId: number): Observable<Array<RecycleRule>> {

        this.requestObservable[processorId] = this.http
            .get<Array<RecycleRule>>(this.resourceUrl + '/' + processorId)
            .pipe(
                tap(
                    (processors: Array<RecycleRule>) => {
                        this.recycleRuleSet[processorId] = processors;
                        this.cacheTimestamp[processorId] = new Date();
                        delete this.requestObservable[processorId];
                    }
                )
            );

        return this.requestObservable[processorId];
    }


    /**
     * Returns true if cached processors is older than cache life setting
     * @param userId
     * @returns {boolean}
     */
    isExpired(processorId) {
        let isExpired = true;

        if (this.cacheTimestamp.hasOwnProperty(processorId)) {
            const currentDate = new Date();
            let diffSeconds = 0;

            const diff = currentDate.getTime() - this.cacheTimestamp[processorId].getTime();
            diffSeconds = Math.floor(diff / 1000);

            if (this.cacheLife >= diffSeconds) {
                isExpired = false;
            }
        }

        //TODO: REMOVE ME
        //console.log(this.constructor.name + ": isExpired = " + isExpired);

        return isExpired;
    }
}
