<div class="container-fluid">

  <div class="page-head">
    <h3>Campaign Pricing</h3>

    <div class="toolbar">
      <button type="button"
              class="btn btn-outline-primary"
              *ngIf="userService.hasAbility('edit-campaign-price')"
              (click)="openModal()"
              ngbTooltip="New Pricing"
              container="body">
        <i class="fa fa-lg fa-plus-circle"></i>
      </button>
    </div>
  </div>

  <div class="card rs-card rs-card-gold card-tabbed">
    <div class="card-body min-paginated-card-height">

      <p class="record-listing">{{ campaigns?.length }} Records</p>

      @if (campaigns) {
        <app-campaign-list-grid
          [campaigns]="campaigns"
          (rowAction)="handleRowAction($event)">
        </app-campaign-list-grid>
      }

    </div>
  </div>
</div>
