import {Component, Inject, OnInit} from '@angular/core';
import {APP_CONFIG} from '../../../app.config';
import {IAppConfig} from '../../../app-config.interface';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    standalone: true
})
export class LoginComponent implements OnInit {

  public googleAuthUrl: string;

  constructor(
    @Inject(APP_CONFIG) public config: IAppConfig,
  ) {
  }


  ngOnInit() {
    this.googleAuthUrl = this.generateGoogleUrl();
  }

  private generateGoogleUrl(): string {
    const url = new URL('https://accounts.google.com/o/oauth2/v2/auth');

    url.searchParams.append('client_id', this.config.googleClientId);
    url.searchParams.append('redirect_uri', window.location.origin + '/auth/google');
    url.searchParams.append('response_type', 'code');
    url.searchParams.append('scope', 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile');

    return url.toString();
  }
}
