import {Component} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {NgbActiveModal, NgbTooltip} from '@ng-bootstrap/ng-bootstrap';
import {CampaignsService} from '../../../services/campaigns.service';
import {CampaignRequestModel} from '../../../models/campaign-request.model';
import {CampaignPriceModel} from '../../../models/campaign-price.model';
import {BillingSchemeService} from '../../../services/billing-scheme.service';
import {ErrorService} from 'src/app/shared/error/error.service';
import {map} from 'rxjs/operators';
import {AlertService} from "@ratespecial/core";
import {FormsModule} from '@angular/forms';
import {ErrorComponent} from 'src/app/shared/error/error.component';
import {CommonModule} from '@angular/common';
import {
  PackageSelectorComponent,
} from 'src/app/modules/campaigns/pages/campaign-list/campaign-modify-modal/package-selector/package-selector.component';
import {SubscriptionPackageDetailsModel} from 'src/app/modules/campaigns/models/subscription-package-details.model';
import {Observable} from 'rxjs';

@Component({
    selector: 'app-campaign-modify-modal',
    templateUrl: './campaign-modify-modal.component.html',
    styleUrls: ['./campaign-modify-modal.component.scss'],
    imports: [
        CommonModule,
        FormsModule,
        ErrorComponent,
        PackageSelectorComponent,
        NgbTooltip,
    ]
})
export class CampaignModifyModalComponent {

  public priceId: number = 0;

  public assignment: CampaignRequestModel = new CampaignRequestModel();
  public scheme$: Observable<any>;

  public isPackageInvalid: boolean = false;
  private currentSchemePrice: string;

  constructor(private alertService: AlertService,
              private activeModal: NgbActiveModal,
              private campaignsService: CampaignsService,
              private billingSchemeService: BillingSchemeService,
              private errorService: ErrorService) {
    this.errorService.clearErrors();

    this.scheme$ = billingSchemeService
      .get()
      .pipe(
        map(resp => {
          return resp.sort((a, b) => {
            return a.internal_desc < b.internal_desc ? 1 : -1;
          });
        }),
      );
  }

  onBillingSchemeChange() {
    this.billingSchemeService.get(false).pipe(
      map(schemes => schemes.find(s => s.bsid === Number(this.assignment.scheme_id))),
    ).subscribe(selectedScheme => {
      if (selectedScheme) {
        this.currentSchemePrice = selectedScheme.recurring_price;
        this.autoSelectPackage(this.currentSchemePrice);
        this.validatePackagePrice();
      }
    });
  }

  private autoSelectPackage(price: string) {
    const priceNumber = parseFloat(price);

    let packageType = '';
    if (priceNumber <= 24.95) {
      packageType = 'club-2024';
    } else {
      packageType = 'extra-2024';
    }

    const newPackage = SubscriptionPackageDetailsModel.getPackageDetails(packageType);
    newPackage.package = packageType;
    this.onPackageChange(newPackage);
  }

  private validatePackagePrice() {
    const packageType = this.assignment.package;
    const priceNumber = parseFloat(this.currentSchemePrice);

    this.isPackageInvalid = (
      (priceNumber <= 24.95 && packageType !== 'club-2024') ||
      (priceNumber >= 24.96 && packageType !== 'extra-2024')
    );
  }

  /**
   * If this modal is adding a new record or updating an existing one
   */
  isAddingNew(): boolean {
    return this.priceId === 0;
  }

  save() {
    this.errorService.clearErrors();

    let o: Observable<CampaignPriceModel>;
    if (!this.isAddingNew()) {
      o = this.campaignsService.update(this.priceId, this.assignment);

    } else {
      o = this.campaignsService.add(this.assignment);
    }

    o.subscribe({
      next: (response: CampaignPriceModel) => {
        this.alertService.success('Success');
        this.close(response);
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.handleValidationErrors(err);
      },
    });
  }


  close(result: any = null) {
    this.activeModal.close(result);
  }

  onPackageChange($event: SubscriptionPackageDetailsModel) {
    this.assignment.applyPackage($event);

    // Ensure the package invalid flag is updated
    this.validatePackagePrice();
  }

}
