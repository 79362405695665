import {enableProdMode, ErrorHandler, importProvidersFrom} from '@angular/core';
import {environment} from './environments/environment';
import {AppComponent} from './app/app.component';
import {provideAnimations} from '@angular/platform-browser/animations';
import {bootstrapApplication, BrowserModule} from '@angular/platform-browser';
import {provideNgxWebstorage, withLocalStorage, withSessionStorage} from 'ngx-webstorage';
import {routes} from './app/app-routing.module';
import {provideRouter, RouterOutlet} from '@angular/router';
import {ServerErrorInterceptor} from 'src/app/shared/interceptor/http/server-error.interceptor';
import {LogoutOnUnauthInterceptor} from 'src/app/shared/interceptor/http/logout-on-unauth.interceptor';
import {AuthJwtInterceptor} from 'src/app/shared/interceptor/http/auth-jwt.interceptor';
import {AlertsComponent, LoadIndicatorComponent, LoadIndicatorInterceptor} from '@ratespecial/core';
import {HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {ActionLogService} from 'src/app/admin/services/action-log.service';
import {AbilityService} from 'src/app/admin/services/ability.service';
import {RoleService} from 'src/app/admin/services/role.service';
import {UserAclService} from 'src/app/admin/services/user-acl.service';
import {TokenStoreService} from 'src/app/shared/auth/services/token-store.service';
import {LoginGuard} from 'src/app/shared/auth/services/login.guard';
import {AuthGuard} from 'src/app/shared/auth/services/auth.guard';
import {HttpErrorService} from 'src/app/shared/http/http-error.service';
import {HistoryService} from 'src/app/shared/auth/services/history.service';
import {ErrorService} from 'src/app/shared/error/error.service';
import {AuthService} from 'src/app/shared/auth/services/auth.service';
import {ActiveModalRegistryFactory} from 'src/app/shared/modal/active-modal-registry.factory';
import {SentryErrorHandler} from './app/shared/errors/sentry-error-handler';
import {APP_CONFIG, AppConfig} from './app/app.config';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(BrowserModule),
    {provide: APP_CONFIG, useValue: AppConfig},
    {provide: ErrorHandler, useClass: SentryErrorHandler},
    ActiveModalRegistryFactory,
    AuthService,
    ErrorService,
    HistoryService,
    HttpErrorService,
    AuthGuard,
    LoginGuard,
    TokenStoreService,
    UserAclService,
    RoleService,
    AbilityService,
    ActionLogService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadIndicatorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthJwtInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LogoutOnUnauthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ServerErrorInterceptor,
      multi: true,
    },
    provideRouter(routes),
    provideHttpClient(withInterceptorsFromDi()),
    provideNgxWebstorage(withLocalStorage(), withSessionStorage()),
    provideAnimations(),
  ],
})
  .catch(err => console.error(err));
