import {Component, OnInit} from '@angular/core';
import {ProcessorListService} from '../services/processor-list.service';
import {Processor} from '../models/processor.model';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { RouterLink } from '@angular/router';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-processor-list',
    templateUrl: './processor-list.component.html',
    styleUrls: ['./processor-list.component.scss'],
    imports: [NgIf, NgFor, RouterLink, NgbTooltip]
})
export class ProcessorListComponent implements OnInit {

  public processors: Array<Processor>;

  constructor(
      private processorService: ProcessorListService
  ) { }


  ngOnInit() {

    this.processorService
      .get(true)
      .subscribe(
        (processors: Array<Processor>) => {
          this.processors = processors;
        });
  }
}
