import {Component, OnInit} from '@angular/core';
import {ProcessClass} from '../models/process-class.model';
import {ProcessClassService} from '../services/process-class.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {HttpErrorResponse} from '@angular/common/http';
import {RecycleRuleService} from '../services/recycle-rule.service';
import {ProcessorRuleChange, UpdateRecycleRuleRequest} from '../models/update-recycle-rule-request.model';
import {ErrorService} from '../../../shared/error/error.service';
import {AlertService} from "@ratespecial/core";
import { FormsModule } from '@angular/forms';
import { NgFor, NgIf } from '@angular/common';
import { ErrorComponent } from '../../../shared/error/error.component';

@Component({
    selector: 'app-process-class-modal',
    templateUrl: './process-class-modal.component.html',
    styleUrls: ['./process-class-modal.component.scss'],
    imports: [ErrorComponent, NgFor, NgIf, FormsModule]
})
export class ProcessClassModalComponent implements OnInit {

  /**
   * Set of all Process Classes that are available for a rule to use
   */
  public processClasses: Array<ProcessClass>;

  /**
   * Add reference to Object.keys function to template
   */
  public objectKeys = Object.keys;

  /**
   * Form errors
   */
  public errors = {};

  /**
   * Form Model
   */
  recycleRuleRequest: UpdateRecycleRuleRequest = {
    processorId: 0,
    processClassId: 0,
    ruleCheckbox: {}
  }


  constructor(
    public activeModal: NgbActiveModal,
    public processClassService: ProcessClassService,
    public recycleRuleService: RecycleRuleService,
    public alertService: AlertService,
    public errorService: ErrorService
  ) { }


  ngOnInit() {

    //Get Available Processing Classes
    this.processClassService.get().subscribe((processClasses: Array<ProcessClass>) => {
      this.processClasses = processClasses;
    });

    this.checkSelectedRules();
  }


  setProcessorId(processorId) {
    this.recycleRuleRequest.processorId = processorId;
  }


  setRecycleRules(processorRuleChange: ProcessorRuleChange) {
    this.recycleRuleRequest.ruleCheckbox = processorRuleChange;
  }

  /**
   * Check we have at least one to change
   */
  checkSelectedRules() {

    var hasAtLeastOne = false;
    this.errorService.clearErrors();

    for (var key in this.recycleRuleRequest.ruleCheckbox) {
      if(this.recycleRuleRequest.ruleCheckbox[key] === true) {
        hasAtLeastOne = true;
      }
    }

    if(hasAtLeastOne === false) {
      this.errorService.addError('Apply To', ['You need to select at least one Processor Rule Checkbox before you can make a process change.']);
    }
  }


  save() {

    this.recycleRuleService.update(this.recycleRuleRequest)
      .subscribe(
        (response) => {
          this.alertService.success('Rules updated');
          this.close();
        },
        (err: HttpErrorResponse) => {
          if (err.status == 422) {
            this.errorService.handleValidationErrors(err);
          }
        }
      );
  }

  close() {
    this.activeModal.close();
  }
}
