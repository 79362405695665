<select
  name="package-selector"
  id="package-selector"
  class="form-control"
  [ngModel]="val"
  (ngModelChange)="onChange($event)">

  <option value="club-2024">Club</option>
  <option value="extra-2024">Extra</option>
  <option value="max-2024" disabled>Max</option>
</select>
