<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">Cascade Assignment</h5>
  <button type="button" class="close clickable" data-dismiss="modal" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">

  <app-error></app-error>

  <form>

    <input type="hidden" name="afid" id="afid" [(ngModel)]="afid">

    <!-- Cascade Assignment -->
    <div class="mb-3 row">
      <label for="cascade" class="col-sm-4 col-form-label">AFID</label>
      <div class="col-sm-8">
        {{(afid) ? afid : 'default'}}
      </div>
    </div>

    <!-- Cascade Assignment -->
    <div class="mb-3 row">
      <label for="cascade" class="col-sm-4 col-form-label">Cascade Assignment</label>
      <div class="col-sm-8">
        <select id="cascade"
                name="cascase"
                class='form-control form-control-sm'
                [(ngModel)]="assignment.cascade_id">
                <!--(change)="assignAfidToCascadeId($event)"-->
          <option [value]="co.cascade_id" *ngFor="let co of cascadeOptions">{{co.cascade_id}} = {{co.proc_ids}}</option>
        </select>
      </div>
    </div>

  </form>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-outline-primary" (click)="save()">Save</button>
  <button type="button" class="btn btn-outline-secondary" (click)="close()">Close</button>
</div>

