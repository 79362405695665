import {EventEmitter, Injectable, Output} from '@angular/core';
import {Observable, of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {tap} from 'rxjs/operators';
import {Processor} from '../models/processor.model';

@Injectable({
  providedIn: 'root'
})
export class ProcessorListService {

  private resourceUrl = '/api/processor/list';

  /**
   * Customer cache stores last 10 customers
   */
  public processors: Array<Processor>;

  /**
   * Observable for current fetch so we can return same observable when multiple requests fire
   */
  public requestObservable: Observable<Array<Processor>>;

  /**
   * Stores timestamp of last processors refresh
   */
  public cacheTimestamp: Date;

  /**
   * Cache lifetime in seconds
   * @type {number}
   */
  private cacheLife = 3600;

  /**
   * Notification to Array<Processor> subscribers of note save events
   * @type {EventEmitter<boolean>}
   */
  @Output() onDataUpdateEvent = new EventEmitter<Array<Processor>>();


  constructor(private http: HttpClient) {

  }

  /**
   * Return cached processors if valid, or request from server
   * @param force
   */
  get(force?: boolean): Observable<Array<Processor>> {

    // If force is true, remove roles from cache
    if (force === true || this.processors.length == 0 || this.isExpired()) {
      this.processors  = null;

    } else {
      // return cached processors
      return of(this.processors);
    }

    return this.request();
  }


  /**
   * Perform Request to server for processors
   */
  private request(): Observable<Array<Processor>> {

    if ( !this.requestObservable ) {
      this.requestObservable = this.http
          .get<Array<Processor>>(this.resourceUrl)
          .pipe(
              tap(
                  (processors: Array<Processor>) => {
                    this.processors = processors;
                    this.cacheTimestamp = new Date();
                  }
              )
          );
      //.share();
    }

    return this.requestObservable;
  }


  /**
   * Returns true if cached processors is older than cache life setting
   * @returns {boolean}
   */
  private isExpired(): boolean {
    let isExpired = false;
    const currentDate = new Date();
    let diffSeconds = 0;

    const diff = currentDate.getTime() - this.cacheTimestamp.getTime();
    diffSeconds = Math.floor(diff/1000);

    if(diffSeconds > this.cacheLife){
      isExpired = true;
    }

    return isExpired;
  }
}
