import {EventEmitter, Injectable, Output} from '@angular/core';
import {Observable, of} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {tap} from 'rxjs/operators';
import {ProcessorMessage} from '../models/processor-message.model';
import {UpdateMessageMapRequest} from '../models/update-message-map-request.model';


@Injectable({
    providedIn: 'root'
})
export class ProcessorMessageService {

    private resourceUrl = '/api/processor/messages';

    /**
     * Data Cache
     */
    public processorMessages: Array<Array<ProcessorMessage>> = [];

    /**
     *
     */
    public dataKeyMap: object = {};

    /**
     * Observable for current fetch so we can return same observable when multiple requests fire
     */
    public requestObservable: Array<Observable<Array<ProcessorMessage>>> = [];

    /**
     * Stores timestamp of last processors refresh
     */
    public cacheTimestamp = {};

    /**
     * Cache lifetime in seconds
     * @type {number}
     */
    private cacheLife = 3600;

    /**
     * Notification to Array<ProcessorMessage> subscribers of note save events
     * @type {EventEmitter<boolean>}
     */
    @Output() onDataUpdateEvent = new EventEmitter<Array<ProcessorMessage>>();


    constructor(private http: HttpClient) {

    }


    update(updateMessageMapRequest: UpdateMessageMapRequest) {
        const headers = new HttpHeaders()
            .set("Content-Type", "application/json");

        return this.http.post(this.resourceUrl, updateMessageMapRequest, {headers}).pipe(
            tap(
                (processors: Array<ProcessorMessage>) => {
                    // Refresh processor rule list
                    this.processorMessages[updateMessageMapRequest.pm_id] = processors;
                    this.cacheTimestamp = new Date();
                    this.onDataUpdateEvent.emit();
                })
        );
    }


    /**
     * Return cached data, or request from server
     * @param force
     */
    get(processorId: number, force?: boolean): Observable<Array<ProcessorMessage>> {

        // If force is true, remove roles from cache
        if (force === true || this.isExpired(processorId)) {
            //console.log(this.constructor.name + ": from server ");   //TODO: REMOVE ME
            return this.request(processorId);
        } else {
            //console.log(this.constructor.name + ": from cache ");   //TODO: REMOVE ME
            return of(this.processorMessages[processorId]);    // return cached processors
        }
    }


    /**
     * Perform Request to server
     */
    private request(processorId: number): Observable<Array<ProcessorMessage>> {

        this.requestObservable[processorId] = this.http
            .get<Array<ProcessorMessage>>(this.resourceUrl + '/' + processorId)
            .pipe(
                tap(
                    (processors: Array<ProcessorMessage>) => {
                        this.processorMessages[processorId] = processors;
                        this.cacheTimestamp = new Date();
                        delete this.requestObservable[processorId];
                    }
                )
            );

        return this.requestObservable[processorId];
    }


    /**
     * Refresh the key map object that maps each primary key to the array index for quick lookup

    refreshKeyMap() {
        this.dataKeyMap = {};
        for (let i in this.processorMessages) {
            this.dataKeyMap[this.processorMessages[i].pm_id] = i;
        }
    }


    getByPrimaryKey(pk: number) {
        return this.processorMessages[this.dataKeyMap[pk]];
    }
*/


    /**
     * Returns true if cached processors is older than cache life setting
     * @param userId
     * @returns {boolean}
     */
    isExpired(processorId) {
        let isExpired = true;

        if (this.cacheTimestamp.hasOwnProperty(processorId)) {
            const currentDate = new Date();
            let diffSeconds = 0;

            const diff = currentDate.getTime() - this.cacheTimestamp[processorId].getTime();
            diffSeconds = Math.floor(diff / 1000);

            if (this.cacheLife >= diffSeconds) {
                isExpired = false;
            }
        }

        return isExpired;
    }
}
