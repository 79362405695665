import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ErrorService} from '../../../shared/error/error.service';
import {HttpErrorResponse} from '@angular/common/http';
import {ProcessorMessage} from '../models/processor-message.model';
import {CommonMessageService} from '../services/common-message.service';
import {CommonMessage} from '../models/common-message.model';
import {UpdateMessageMapRequest} from '../models/update-message-map-request.model';
import {ProcessorMessageService} from '../services/processor-message.service';
import {AlertService} from "@ratespecial/core";
import { NgFor } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ErrorComponent } from '../../../shared/error/error.component';

@Component({
    selector: 'app-message-map-modal',
    templateUrl: './message-map-modal.component.html',
    styleUrls: ['./message-map-modal.component.scss'],
    imports: [ErrorComponent, FormsModule, NgFor]
})
export class MessageMapModalComponent implements OnInit {

  /**
   * Processor Message to update
   */
  public processorMessage: ProcessorMessage;

  /**
   * Set of all Process Classes that are available for a rule to use
   */
  public commonMessages: Array<CommonMessage>;

  /**
   * Add reference to Object.keys function to template
   */
  public objectKeys = Object.keys;


  public messageMapRequest: UpdateMessageMapRequest = {
    pm_id: 0,
    cm_id: 0,
    max_tries: 0,
  }

  constructor(
      public activeModal: NgbActiveModal,
      public processorMessageService: ProcessorMessageService,
      public commonMessageService: CommonMessageService,
      public alertService: AlertService,
      public errorService: ErrorService
  ) { }


  ngOnInit() {
    //Get Common Messages
    this.commonMessageService.get().subscribe((commonMessages: Array<CommonMessage>) => {
       this.commonMessages = commonMessages;
    });
  }


  setProcessorMessage(processorMessage) {
    this.processorMessage = processorMessage;
    this.messageMapRequest.pm_id = processorMessage.pm_id;
    this.messageMapRequest.cm_id = processorMessage.cm_id;
    this.messageMapRequest.max_tries = processorMessage.max_tries;
  }


  save() {
    this.processorMessageService.update(this.messageMapRequest)
      .subscribe(
            (response) => {
              this.alertService.success('Message Updated');
              this.close();
            },
            (err: HttpErrorResponse) => {
              if (err.status == 422) {
                //this.errors = err.error;
                this.errorService.handleValidationErrors(err);
              }
            }
        );
  }


  close() {
    this.activeModal.close();
  }
}
