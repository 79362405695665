import {Routes} from '@angular/router';
import {NavbarComponent} from './layout/navbar/navbar.component';
import {FooterComponent} from './layout/footer/footer.component';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
import {AuthGuard} from './shared/auth/services/auth.guard';
import {AdminComponent} from './admin/admin.component';
import {AclComponent} from './admin/acl/acl.component';
import {UserDetailComponent} from './admin/user/user-detail/user-detail.component';
import {UserListComponent} from './admin/user/user-list/user-list.component';
import {UserResolver} from './shared/auth/resolvers/user.resolver';
import {CampaignListComponent} from 'src/app/modules/campaigns/pages/campaign-list/campaign-list.component';
import {RdrImportPageComponent} from 'src/app/modules/rdr-import/pages/rdr-import-page/rdr-import-page.component';
import {LoginComponent} from 'src/app/modules/auth/login/login.component';
import {LoginGuard} from 'src/app/shared/auth/services/login.guard';
import {GoogleComponent} from 'src/app/modules/auth/social/google/google.component';
import {ProcessorListComponent} from 'src/app/modules/processor/processor-list/processor-list.component';
import {ProcessorDetailComponent} from 'src/app/modules/processor/processor-detail/processor-detail.component';
import {
  AfidAssignmentListComponent,
} from 'src/app/modules/billing-cascade/afid-assignment/afid-assignment-list/afid-assignment-list.component';
import {StickyIoComponent} from 'src/app/modules/account-updater/pages/sticky-io/sticky-io.component';
import {RrHomeComponent} from 'src/app/modules/round-robin-billing/pages/rr-home/rr-home.component';
import {RrHomeResolver} from 'src/app/modules/round-robin-billing/pages/rr-home/rr-home.resolve';

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'campaigns',
  },
  {
    path: 'auth',
    pathMatch: 'prefix',
    children: [
      {
        path: '',
        pathMatch: 'prefix',
        children: [
          {path: 'login', component: LoginComponent, canActivate: [LoginGuard]},
          {path: 'google', component: GoogleComponent, canActivate: [LoginGuard]},
        ],
      },
    ],
  },
  {
    path: '',
    pathMatch: 'prefix',
    canActivate: [AuthGuard],
    resolve: {
      user: UserResolver,
    },
    children: [
      {path: '', pathMatch: 'prefix', component: NavbarComponent, outlet: 'header'},
      {path: '', pathMatch: 'prefix', component: FooterComponent, outlet: 'footer'},
      {path: '', pathMatch: 'full', redirectTo: 'campaigns'},

      {
        path: 'round-robin',
        children: [
          {
            path: '',
            pathMatch: 'prefix',
            redirectTo: 'home',
            data: {scrollToTag: 'dashTop'},
          },
          {
            path: 'home',
            component: RrHomeComponent,
            resolve: {
              productMap: RrHomeResolver,
            },
          },
        ],
      },

      {
        path: 'billing-cascade',
        pathMatch: 'prefix',
        children: [
          {
            path: '',
            pathMatch: 'prefix',
            children: [
              {path: 'afid-assignment', component: AfidAssignmentListComponent},
            ],
          },
        ],
      },

      {
        path: 'processor',
        pathMatch: 'prefix',
        children: [
          // To get nav highlight working on processor/<some-child> route, make a default for
          // processor/ since it's not actually mapped to any component redirect to processor/list
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'list',
          },
          {
            path: '',
            pathMatch: 'prefix',
            children: [
              {path: 'list', component: ProcessorListComponent},
              {path: 'detail', component: ProcessorDetailComponent},
            ],
          },
        ],
      },
      {
        path: 'account-updater',
        pathMatch: 'prefix',
        children: [
          {
            path: '',
            pathMatch: 'prefix',
            redirectTo: 'sticky',
            data: {scrollToTag: 'dashTop'},
          },
          {
            path: 'sticky',
            component: StickyIoComponent,
          },
        ],
      },

      {
        path: 'campaigns',
        pathMatch: 'prefix',
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'list',
          },
          {
            path: '',
            pathMatch: 'prefix',
            children: [
              {path: 'list', component: CampaignListComponent},
            ],
          },
        ],
      },

      {
        path: 'rdr-import',
        pathMatch: 'prefix',
        children: [
          {
            path: '',
            pathMatch: 'prefix',
            component: RdrImportPageComponent,
            data: {scrollToTag: 'dashTop'},
          },
        ],
      },

      {
        path: 'admin',
        component: AdminComponent,
        children: [
          {path: 'acl', component: AclComponent},
          {path: 'user/:id', component: UserDetailComponent},
          {path: 'users', component: UserListComponent},
        ],
      },
      {path: '**', component: PageNotFoundComponent},
    ],
  },
];
