import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {AccountUpdaterService} from '../../services/account-updater.service';
import {AccountsUpdatedModel} from '../../models/accounts-updated.model';
import {AlertService} from "@ratespecial/core";
import { NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-sticky-io',
    templateUrl: './sticky-io.component.html',
    styleUrls: ['./sticky-io.component.scss'],
    imports: [FormsModule, NgIf]
})
export class StickyIoComponent implements OnInit {

  @ViewChild('fileInput') fileInput: ElementRef;
  public csvFile;
  public result: AccountsUpdatedModel;

  constructor(
    private accountUpdaterService: AccountUpdaterService,
    private alertService: AlertService,
  ) {
  }

  ngOnInit() {
  }

  upload() {
    const fileBrowser = this.fileInput.nativeElement;

    if (fileBrowser.files && fileBrowser.files[0]) {
      const form = new FormData();
      form.append('csv', fileBrowser.files[0]);
      this.accountUpdaterService
        .upload(form)
        .subscribe( result => {
          this.result = result;
          this.csvFile = null;
        });
    } else {
      this.alertService.error('No file selected');
    }

  }


}
