<ag-grid-angular
  class="ag-theme-quartz"
  #agGrid
  [columnDefs]="columnDefs"
  [rowData]="campaigns"
  [defaultColDef]="defaultColDef"
  rowSelection="single"
  style="width: 100%; height: 81vh; overflow: auto;"
  [tooltipShowDelay]="tooltipShowDelay">
</ag-grid-angular>
