import {Component, OnDestroy, OnInit} from '@angular/core';
import {Processor} from '../models/processor.model';
import {ProcessorDetailService} from '../services/processor-detail.service';
import {ActivatedRoute} from '@angular/router';
import {forkJoin, Subscription} from 'rxjs';
import {ProcessClassService} from '../services/process-class.service';
import {ProcessClass} from '../models/process-class.model';
import {ProcessorMessage} from '../models/processor-message.model';
import {ProcessorMessageService} from '../services/processor-message.service';
import {RecycleRule} from '../models/recycle-rule.model';
import {RecycleRuleService} from '../services/recycle-rule.service';
import { NgbModal, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import {ProcessClassModalComponent} from '../process-class-modal/process-class-modal.component';
import {ProcessorRuleChange} from '../models/update-recycle-rule-request.model';
import {MessageMapModalComponent} from '../message-map-modal/message-map-modal.component';
import {CommonMessageService} from '../services/common-message.service';
import {CommonMessage} from '../models/common-message.model';
import {UserService} from '../../../shared/auth/services/user.service';
import { FormsModule } from '@angular/forms';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-processor-detail',
    templateUrl: './processor-detail.component.html',
    styleUrls: ['./processor-detail.component.scss'],
    imports: [NgIf, NgbTooltip, NgFor, FormsModule]
})
export class ProcessorDetailComponent implements OnInit, OnDestroy {

  public hasLoaded = false;
  public currentProcessorId = 0;
  public processor: Processor;
  public processClasses: Array<ProcessClass>;
  public processorMessages: Array<ProcessorMessage>;
  public recycleRules: Array<RecycleRule>;
  private subscriptions: Array<Subscription> = [];
  public ruleCheckbox: ProcessorRuleChange = {};

  constructor(
      private activatedRoute: ActivatedRoute,
      private processClassService: ProcessClassService,
      private processorMessageService: ProcessorMessageService,
      private processorDetailService: ProcessorDetailService,
      private recycleRuleService: RecycleRuleService,
      private commonMessageService: CommonMessageService,
      private modalService: NgbModal,
      public userService: UserService,
  ) { }


  ngOnInit() {

    // Watch route changes
    this.activatedRoute.queryParams.subscribe(params => {
      this.currentProcessorId = (params['id']) ? params['id'] : this.activatedRoute.snapshot.params['id'];
      this.getAllResources();
    });

    // Get Common Messages (currently static)
    this.commonMessageService.get().subscribe((commonMessages: Array<CommonMessage>) => {
      // We don't need it here, just prefetching to make modal interactions faster
    });

    this.processClassService.get().subscribe((processClasses: Array<ProcessClass>) => {
      this.processClasses = processClasses;
    });

    // Watch for Recycle Rule Changes and update view
    this.subscriptions.push(
      this.recycleRuleService.onDataUpdateEvent.subscribe((recycleRules: Array<RecycleRule>) => {
        this.recycleRules = recycleRules;
    }));

    // Watch for Processor Message Changes and update view
    this.subscriptions.push(
      this.processorMessageService.onDataUpdateEvent.subscribe((processorMessages: Array<ProcessorMessage>) => {
        this.refreshProcessorMessages();
    }));
  }


  /**
   *  Fetch all resources waiting for each observable to complete before post processing
   */
  getAllResources() {
    forkJoin([
        this.processorDetailService.get(this.currentProcessorId),
        this.processorMessageService.get(this.currentProcessorId),
        this.recycleRuleService.get(this.currentProcessorId)

    ]).subscribe(([processor, processorMessages, recycleRules]: [Processor, Array<ProcessorMessage>, Array<RecycleRule>]) => {
        this.processor = processor;
        this.processorMessages = processorMessages;
        this.recycleRules = recycleRules;

        // Initialize Checkboxes for each rule
        for (const [key, rule] of Object.entries(recycleRules)) {
          this.ruleCheckbox[rule.id] = false;
        }

        this.hasLoaded = true;
    });
  }


  refreshProcessorMessages() {
    this.processorMessageService.get(this.currentProcessorId)
      .subscribe((processorMessages: Array<ProcessorMessage>) => {
        this.processorMessages = processorMessages;
      });
  }


  openProcessClassModal() {
    const modalRef = this.modalService.open(ProcessClassModalComponent);
    modalRef.componentInstance.setProcessorId(this.currentProcessorId);
    modalRef.componentInstance.setRecycleRules(this.ruleCheckbox);
  }


  openMessageMapModal(message) {
    const modalRef = this.modalService.open(MessageMapModalComponent, {size: 'lg'});
    modalRef.componentInstance.setProcessorMessage(message);
    // modalRef.componentInstance.setProcessorMessage(this.processorMessages[messageId]);
  }


  ngOnDestroy() {
    this.subscriptions.map( (s: Subscription) => {
      s.unsubscribe();
    });
  }

}
