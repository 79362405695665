import {EventEmitter, Injectable, Output} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ProductInterface} from '../models/product.interface';
import {Observable, of} from 'rxjs';
import {tap} from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class ProductService {

  private resourceUrl = '/api/products/afids';

  /**
   *
   */
  public products: Array<ProductInterface> = [];

  /**
   * Observable for current fetch so we can return same observable when multiple requests fire
   */
  public requestObservable: Observable<Array<ProductInterface>> = null;

  /**
   * Stores timestamp of last data refresh
   */
  public cacheTimestamp: Date = new Date();

  /**
   * Cache lifetime in seconds
   * @type {number}
   */
  private cacheLife = 3600;

  /**
   * Notification to any subscribers of note save events
   * @type {EventEmitter<boolean>}
   */
  @Output() onProductUpdateEvent = new EventEmitter<boolean>();


  constructor(private http: HttpClient) {
    this.products = [];
  }


  /**
   * Return cached data if valid, or request from server
   * @param force
   */
  get(force: boolean = false): Observable<Array<ProductInterface>> {

    // console.log({
    //   force: force,
    //   products: this.products,
    //   isExpired: this.isExpired
    // });

    // If force is true, remove from cache
    if (force === true || this.products.length == 0 || this.isExpired() === true) {
      this.products = null;
      this.requestObservable = null;

    } else {
      // return cached data
      return of(this.products);
    }

    return this.request();
  }


  /**
   * Perform Request to server for data
   */
  private request(): Observable<Array<ProductInterface>> {

    if ( !this.requestObservable ) {
      this.requestObservable = this.http
          .get<Array<ProductInterface>>(this.resourceUrl)
          .pipe(
              tap(
                  products => {
                    this.products = products;
                    this.cacheTimestamp = new Date();
                  }
              )
          );
    }

    return this.requestObservable;
  }


  /**
   * Returns true if cached data is older than cache life setting
   * @returns {boolean}
   */
  private isExpired(): boolean {
    let isExpired = false;
    const currentDate = new Date();
    let diffSeconds = 0;

    const diff = currentDate.getTime() - this.cacheTimestamp.getTime();
    diffSeconds = Math.floor(diff / 1000);

    if (diffSeconds > this.cacheLife) {
      isExpired = true;
    }

    return isExpired;
  }
}
