

<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">Update Processor Message Mapping</h5>
  <button type="button" class="close clickable" data-dismiss="modal" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">

  <app-error></app-error>

  <!-- Rules -->
  <p>Please select the Common Message you would like to map the Processor Message to:</p>

  <!--{{processorMessage.pm_id}}<br>-->
  <!--{{processorMessage.proc_id}}<br>-->
  <!--{{processorMessage.proc_resp_code}}<br>-->
  <!--{{processorMessage.proc_message}}<br>-->

  <form>
    <input type="hidden" name="pmid" id="pmid" [(ngModel)]="messageMapRequest.pm_id">

    <div class="mb-3 row">
      <label for="pc" class="col-sm-4 col-form-label">Processor Message</label>
      <div class="col-sm-8">
        <p class="m-2">{{processorMessage.proc_resp_code}} {{processorMessage.proc_message}}</p>
      </div>
    </div>


    <!-- Process -->
    <div class="mb-3 row">
      <label for="pc" class="col-sm-4 col-form-label">Common Message</label>
      <div class="col-sm-8">
        <select id="pc"
                name="pc"
                class='form-control form-control-sm'
                [(ngModel)]="messageMapRequest.cm_id">
            <option [value]="cm.cm_id" *ngFor="let cm of commonMessages">{{cm.cm_id}} - {{cm.message}}</option>
        </select>
      </div>
    </div>

    <!-- Process -->
    <div class="mb-3 row">
      <label for="pc" class="col-sm-4 col-form-label">Max Retries</label>
      <div class="col-sm-8">
        <input id="max_retries"
                name="max_retries"
                class='form-control form-control-sm'
                [(ngModel)]="messageMapRequest.max_tries" />
      </div>
    </div>

  </form>

  <!--<pre>{{processorMessage | json}}</pre>-->
  <!--<pre>{{commonMessages | json}}</pre>-->
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-outline-primary" (click)="save()">Save</button>
  <button type="button" class="btn btn-outline-secondary" (click)="close()">Close</button>
</div>

