import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {ProductStatInterface} from '../../models/product-stat.interface';
import {Observable} from 'rxjs';
import {ProcessorListService} from '../../../processor/services/processor-list.service';
import {Processor} from '../../../processor/models/processor.model';
import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class RrHomeResolver {

  constructor(private processorListService: ProcessorListService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Map<number, ProductStatInterface>> | Promise<Map<number, ProductStatInterface>> | Map<number, ProductStatInterface> {
    // Build our own observable of the correct type
    return new Observable<Map<number, ProductStatInterface>>(obs => {

      // Fetch the list of processors
      this.processorListService
        .get(true)
        .subscribe((processors: Array<Processor>) => {
          // Turn the list of processors into a product map
          const results = this.processResults(processors);

          // Fire that to the subscription and complete
          obs.next(results);
          obs.complete();
        }, (e) => {
          obs.error(e);
        });
    });
  }

  /**
   * Restructure array of processors into a map of product -> processor[]
   * @param procs
   */
  private processResults(procs: Array<Processor>): Map<number, ProductStatInterface> {
    const results = new Map<number, ProductStatInterface>();

    procs
      .filter((v) => v.active)
      .forEach((v) => {
        if (results.has(v.product_id)) {
          results.get(v.product_id)
            .processors
            .push(v);
        } else {
          results.set(v.product_id, {
            id: v.product_id,
            name: v.product_name,
            processors: [v],
          });
        }
      });

    return results;
  }
}
