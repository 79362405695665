export class SubscriptionPackageDetailsModel {
  dine_membership: boolean = false;
  darkweb_emails: number = 2;
  package: string = '';
  darkweb_init_phone: boolean = false;

  static getPackageDetails(pkg: string): SubscriptionPackageDetailsModel {
    const r = new SubscriptionPackageDetailsModel();
    r.package = pkg;
    r.darkweb_emails = 2;

    switch (pkg) {
      case 'prime':
        r.dine_membership = true;
        break;

      case 'extra-2024':
      case 'max-2024':
        r.darkweb_init_phone = true;
        r.dine_membership = true;
        break;

      case 'prime-plus':
        r.darkweb_emails = 25;
        r.dine_membership = true;
        break;
    }

    return r;
  }
}
