import {Component, Input, Output, EventEmitter} from '@angular/core';
import {AgGridAngular} from 'ag-grid-angular';
import {
  ModuleRegistry,
  ClientSideRowModelModule,
  TextFilterModule,
  NumberFilterModule,
  ColDef,
  TooltipModule,
} from 'ag-grid-community';
import {CampaignPriceModel} from '../../../../models/campaign-price.model';

// Register only the Community Modules you need
ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  TextFilterModule,
  NumberFilterModule,
  TooltipModule,
]);

@Component({
    selector: 'app-campaign-list-grid',
    imports: [AgGridAngular],
    templateUrl: './campaign-list-grid.component.html',
    styleUrl: './campaign-list-grid.component.scss'
})
export class CampaignListGridComponent {
  @Input() campaigns: CampaignPriceModel[] = [];
  @Output() rowAction = new EventEmitter<CampaignPriceModel>();

  public tooltipShowDelay = 500;

  columnDefs: ColDef[] = [
    {
      field: 'cid',
      headerName: 'CID',
      minWidth: 100,
      flex: 1,
      filter: 'agTextColumnFilter',
      sort: 'desc',
      filterParams: {
        filterOptions: ['contains'],
        defaultOption: 'contains',
      },
    },
    {
      field: 'afid',
      headerName: 'AFID',
      minWidth: 100,
      flex: 1,
      filter: 'agTextColumnFilter',
      filterParams: {
        filterOptions: ['contains'],
        defaultOption: 'contains',
      },
    },
    {field: 'internal_desc', headerName: 'Internal Description', minWidth: 250, flex: 2},
    {field: 'free_trial_days', headerName: 'Trial Days', minWidth: 100, flex: 1},
    {field: 'auth_type', headerName: 'Auth Type', minWidth: 100, flex: 1},
    {field: 'auth_amount', headerName: 'Auth Amount', minWidth: 130, flex: 1, valueFormatter: params => `£${params.value}`},
    {field: 'initial_price', headerName: 'Initial Price', minWidth: 130, flex: 1, valueFormatter: params => `£${params.value}`},
    {field: 'recurring_price', headerName: 'Recurring Price', minWidth: 130, flex: 1, valueFormatter: params => `£${params.value}`},
    {field: 'package', headerName: 'Sub Package', minWidth: 100, flex: 1},
    {
      field: 'broken_enrollment',
      headerName: 'Broken Enroll',
      minWidth: 130,
      flex: 1,
      cellRenderer: this.renderDot.bind(this),
    },
    {
      field: 'direct_debit',
      headerName: 'Direct Debit',
      minWidth: 130,
      flex: 1,
      cellRenderer: this.renderDot.bind(this),
    },
    {
      field: 'deferred_auth',
      headerName: 'Deferred Auth',
      minWidth: 130,
      flex: 1,
      cellRenderer: this.renderDot.bind(this),
    },
    {
      headerName: 'Actions',
      minWidth: 100,
      flex: 1,
      sortable: false,
      filter: false,
      cellRenderer: (params) => {
        const button = document.createElement('i');
        button.classList.add('fas', 'fa-eye', 'clickable');
        button.setAttribute('title', 'View Detail');
        button.addEventListener('click', () => this.rowAction.emit(params.data));
        return button;
      },
    },
  ];

  defaultColDef: ColDef = {
    resizable: true,
    sortable: true,
    filter: true,
    tooltipComponent: 'agTooltipComponent',
  };

  /**
   * Takes a cell value from ag-grid, considering it a boolean, and renders either a green or red dot
   */
  private renderDot(params) {
    const icon = document.createElement('i');
    icon.classList.add('fas', 'fa-circle');
    icon.classList.add(params.value ? 'text-success' : 'text-danger');
    return icon;
  }

}
