import { Component, OnInit } from '@angular/core';
import {AbilityService} from '../../services/ability.service';
import {Ability} from '../../models/ability.model';
import {AlertService} from "@ratespecial/core";
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-add-ability',
    templateUrl: './add-ability.component.html',
    styleUrls: ['./add-ability.component.css'],
    imports: [FormsModule]
})
export class AddAbilityComponent implements OnInit {

  public ability = '';

  constructor(
      private alertService: AlertService,
      private abilityService: AbilityService
  ) { }

  ngOnInit() {
  }

  addAbility() {
    this.abilityService.add(this.ability).subscribe((ability: Ability) => {
      this.ability = '';
      this.alertService.success('Ability has been added');
    });
  }
}
