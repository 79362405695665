import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from "./auth.service";


/**
 * Prevent back button to the login or auth processing pages when user is already authenticated
 *
 * To use:
 *    Add guard to your login related routes
 *    {path: 'login', component: LoginComponent, canActivate: [LoginGuard]},
 *    {path: 'google', component: GoogleComponent, canActivate: [LoginGuard]}
 */
@Injectable()
export class LoginGuard {

  constructor(
    private authService: AuthService,
    private router: Router,
  ) {
  }

  canActivate(next: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.authService.isAuthenticated()) {
            console.log('login guard check');
            this.router.navigateByUrl('/');
            return false;
        }
        else {
            return true;
        }
    }
}
