<div class="container">
  <div class="container">

    <div class="page-head">
      <h3 class="">Processors</h3>
    </div>

    <div class="card rs-card rs-card-gold card-tabbed">
      <div class="card-body min-paginated-card-height">

        <p class="record-listing">{{processors?.length}} Records</p>

        <table class="margin-top-15 table table-sm rs-table table-striped" *ngIf="processors">
          <thead class="">
          <tr>
            <th class="text-center">ID</th>
            <th>Name</th>
            <th class="text-center">MID</th>
            <th class="text-center">Product ID</th>
            <th class="text-center">Recurring Billing</th>
            <th class="text-center">Actions</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let p of processors">
            <td class="text-center">{{p.pid}}</td>
            <td class="">{{p.processor_name}}</td>
            <td class="text-center">{{p.mid}}</td>
            <td class="text-center">{{p.product_id}}</td>

            <td class="text-center">{{p.recurring_bill}}</td>
            <td class="text-center">
              <a [routerLink]="['/processor/detail', { id: p.pid }]">
                <i class="fas fa-eye clickable" ngbTooltip="View Processor Detail" container="body"></i>
              </a>
            </td>
          </tr>
          </tbody>
        </table>

      </div>
    </div>
  </div>
</div>
