import {EventEmitter, Injectable, Output} from '@angular/core';
import {Observable, of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {tap} from 'rxjs/operators';
import {CommonMessage} from '../models/common-message.model';

@Injectable({
  providedIn: 'root'
})
export class CommonMessageService {

  private resourceUrl = '/api/common-messages';

  /**
   * Customer cache stores last 10 customers
   */
  public commonMessages: Array<CommonMessage> = [];

  /**
   * Observable for current fetch so we can return same observable when multiple requests fire
   */
  public requestObservable: Observable<Array<CommonMessage>>;

  /**
   * Stores timestamp of last processors refresh
   */
  public cacheTimestamp: Date;

  /**
   * Cache lifetime in seconds
   * @type {number}
   */
  private cacheLife = 3600;

  /**
   * Notification to Array<CommonMessage> subscribers of note save events
   * @type {EventEmitter<boolean>}
   */
  @Output() onDataUpdateEvent = new EventEmitter<Array<CommonMessage>>();


  constructor(private http: HttpClient) {

  }

  /**
   * Return cached data, or request from server
   * @param force
   */
  get(force?: boolean): Observable<Array<CommonMessage>> {

    // If force is true, remove roles from cache
    if (force === true || this.commonMessages.length == 0 || this.isExpired()) {
      this.commonMessages  = null;

    } else {
      // return cached processors
      return of(this.commonMessages);
    }

    return this.request();
  }


  /**
   * Perform Request to server
   */
  private request(): Observable<Array<CommonMessage>> {

    if ( !this.requestObservable ) {
      this.requestObservable = this.http
          .get<Array<CommonMessage>>(this.resourceUrl)
          .pipe(
              tap(
                  (processors: Array<CommonMessage>) => {
                    this.commonMessages = processors;
                    this.cacheTimestamp = new Date();
                  }
              )
          );
      //.share();
    }

    return this.requestObservable;
  }


  /**
   * Returns true if cached processors is older than cache life setting
   * @param userId
   * @returns {boolean}
   */
  isExpired() {
    let isExpired = false;
    const currentDate = new Date();
    let diffSeconds = 0;

    const diff = currentDate.getTime() - this.cacheTimestamp.getTime();
    diffSeconds = Math.floor(diff/1000);

    if(diffSeconds > this.cacheLife){
      isExpired = true;
    }

    return isExpired;
  }
}
