import {Component, OnInit} from '@angular/core';
import {ProcessorService} from '../../../../shared/services/processor.service';
import {ProductStatInterface} from '../../models/product-stat.interface';
import {ActivatedRoute} from '@angular/router';
import {AlertService} from "@ratespecial/core";
import { FormsModule } from '@angular/forms';
import { NgFor, KeyValuePipe } from '@angular/common';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-rr-home',
    templateUrl: './rr-home.component.html',
    styleUrls: ['./rr-home.component.scss'],
    imports: [NgbTooltip, NgFor, FormsModule, KeyValuePipe]
})
export class RrHomeComponent implements OnInit {

  public products: Map<number, ProductStatInterface>;

  constructor(
    private processorService: ProcessorService,
    private alertService: AlertService,
    private activatedRoute: ActivatedRoute,
  ) {
  }

  ngOnInit() {
    this.activatedRoute
      .data
      .subscribe(d => {
        this.products = d['productMap'];
      });
  }

  save() {
    const data: object[] = [];

    // Flatten the product -> processor[] structure into a simple array of object data
    this.products
      .forEach(prod => {
        prod
          .processors
          .forEach(proc => {
            data.push({
              pid: proc.pid,
              rr_cap: proc.rr_cap,
            });
          });

      });

    this.processorService
      .update(data)
      .subscribe(() => {
        this.alertService.success('Saved');
      }, () => {
        this.alertService.error('Failed');
      });
  }

  capTotalForProduct(pid: number): number {
    let cnt = 0;

    this.products
      .get(pid)
      .processors
      .forEach(p => {
        cnt += +p.rr_cap
      });

    return cnt;
  }
}
