import {EventEmitter, Injectable, Output} from '@angular/core';
import {Observable, of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {tap} from 'rxjs/operators';
import {ProcessClass} from '../models/process-class.model';

@Injectable({
  providedIn: 'root'
})
export class ProcessClassService {

  private resourceUrl = '/api/processor/classes';

  /**
   * Customer cache stores last 10 customers
   */
  public processClasses: Array<ProcessClass> = [];

  /**
   * Observable for current fetch so we can return same observable when multiple requests fire
   */
  public requestObservable: Observable<Array<ProcessClass>>;

  /**
   * Stores timestamp of last processors refresh
   */
  public cacheTimestamp: Date;

  /**
   * Cache lifetime in seconds
   * @type {number}
   */
  private cacheLife = 3600;

  /**
   * Notification to Array<ProcessClass> subscribers of note save events
   * @type {EventEmitter<boolean>}
   */
  @Output() onDataUpdateEvent = new EventEmitter<Array<ProcessClass>>();


  constructor(private http: HttpClient) {

  }

  /**
   * Return cached processors if valid, or request from server
   * @param force
   */
  get(force?: boolean): Observable<Array<ProcessClass>> {

    // If force is true, remove roles from cache
    if (force === true || this.processClasses.length == 0 || this.isExpired()) {
      this.processClasses  = null;

    } else {
      // return cached processors
      //console.log(this.constructor.name + ": from cache ");   //TODO: REMOVE ME
      return of(this.processClasses);
    }

    return this.request();
  }


  /**
   * Perform Request to server for processors
   */
  private request(): Observable<Array<ProcessClass>> {

    if ( !this.requestObservable ) {
      this.requestObservable = this.http
          .get<Array<ProcessClass>>(this.resourceUrl)
          .pipe(
              tap(
                  (processors: Array<ProcessClass>) => {
                    //console.log(this.constructor.name + ": from server ");   //TODO: REMOVE ME
                    this.processClasses = processors;
                    this.cacheTimestamp = new Date();
                  }
              )
          );
      //.share();
    }

    return this.requestObservable;
  }


  /**
   * Returns true if cached processors is older than cache life setting
   * @param userId
   * @returns {boolean}
   */
  isExpired() {
    let isExpired = false;
    const currentDate = new Date();
    let diffSeconds = 0;

    const diff = currentDate.getTime() - this.cacheTimestamp.getTime();
    diffSeconds = Math.floor(diff/1000);

    if(diffSeconds > this.cacheLife){
      isExpired = true;
    }

    //TODO: REMOVE ME
    //console.log(this.constructor.name + ": isExpired = " + isExpired);

    return isExpired;
  }
}
