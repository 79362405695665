import {EventEmitter, Injectable, Output} from '@angular/core';
import {Observable, of} from 'rxjs';
import {tap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {GenericProductCascade} from '../models/cascade-options.interface';


@Injectable({
  providedIn: 'root'
})
export class CascadeOptionsService {

  private resourceUrl = '/api/cascade/options';

  /**
   * Customer cache stores last 10 customers
   */
  public cascadeOptionsByProduct: GenericProductCascade = null;

  /**
   * Observable for current fetch so we can return same observable when multiple requests fire
   */
  public requestObservable: Observable<GenericProductCascade>;

  /**
   * Stores timestamp of last data refresh
   */
  public cacheTimestamp: Date;

  /**
   * Cache lifetime in seconds
   * @type {number}
   */
  private cacheLife = 3600;

  /**
   * Notification to any subscribers of note save events
   * @type {EventEmitter<boolean>}
   */
  @Output() onCascadeOptionsUpdate = new EventEmitter<any>();


  constructor(private http: HttpClient) {

  }


  /**
   * Return cached data if valid, or request from server
   * @param force
   */
  get(force?: boolean): Observable<GenericProductCascade> {

    // If force is true, remove roles from cache
    if (force === true || this.cascadeOptionsByProduct === null || this.isExpired()) {
      this.cascadeOptionsByProduct  = null;

    } else {
      // return cached data
      return of(this.cascadeOptionsByProduct);
    }

    return this.request();
  }


  /**
   * Perform Request to server for data
   */
  private request(): Observable<GenericProductCascade> {

    if ( !this.requestObservable ) {
      this.requestObservable = this.http
        .get<GenericProductCascade>(this.resourceUrl)
        .pipe(
          tap(
            cascadeOptionsByProduct => {
              this.cascadeOptionsByProduct = cascadeOptionsByProduct;
              this.cacheTimestamp = new Date();
              this.onCascadeOptionsUpdate.emit();
            }
          )
        );
    }

    return this.requestObservable;
  }


  /**
   * Returns true if cached data is older than cache life setting
   */
  isExpired(): boolean {
    let isExpired = false;
    const currentDate = new Date();
    let diffSeconds = 0;

    const diff = currentDate.getTime() - this.cacheTimestamp.getTime();
    diffSeconds = Math.floor(diff / 1000);

    if (diffSeconds > this.cacheLife) {
      isExpired = true;
    }
    return isExpired;
  }
}
