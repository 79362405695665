import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {CampaignPriceModel} from '../models/campaign-price.model';
import {map, share, tap} from 'rxjs/operators';
import {CampaignRequestModel} from '../models/campaign-request.model';

@Injectable({
  providedIn: 'root'
})
export class CampaignsService {
  private isFetching = false;
  private campaignsObservable: Observable<Array<CampaignPriceModel>>;
  private campaignPricing: Array<CampaignPriceModel>;

  constructor(private http: HttpClient) {
  }

  public get(force: boolean = false): Observable<Array<CampaignPriceModel>> {

    if (force === true) {
      this.campaignPricing = undefined;
    }

    // if we already have retrieved the user data from the server, return it
    if (this.campaignPricing) {
      return of(this.campaignPricing)
        .pipe(
          map(
            resp => resp as Array<CampaignPriceModel>
          )
        );
    }

    return this.fetch();
  }

  private fetch(): Observable<Array<CampaignPriceModel>> {

    if (this.isFetching === false) {
      this.isFetching = true;

      this.campaignsObservable = this.http
        .get<Array<CampaignPriceModel>>('/api/campaign-prices')
        .pipe(
          share(),
          tap(
            resp => {
              this.isFetching = false;
              this.campaignPricing = resp;
            }
          )
        );
    }

    return this.campaignsObservable;
  }

  public update(priceId: number, data: CampaignRequestModel): Observable<CampaignPriceModel> {
    return this.http.put<CampaignPriceModel>('/api/campaign-prices/' + priceId, data);
  }

  public add(data: CampaignRequestModel): Observable<CampaignPriceModel> {
    return this.http.post<CampaignPriceModel>('/api/campaign-prices', data);
  }
}
