import {Component, Inject, OnInit} from '@angular/core';
import {APP_CONFIG} from './app.config';
import {IAppConfig} from './app-config.interface';
import {Title} from '@angular/platform-browser';
import { AlertsComponent, LoadIndicatorComponent } from '@ratespecial/core';
import { RouterOutlet } from '@angular/router';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    imports: [
        RouterOutlet,
        AlertsComponent,
        LoadIndicatorComponent,
    ]
})
export class AppComponent implements OnInit {

  title = '';

  constructor(
    @Inject(APP_CONFIG) public config: IAppConfig,
    private titleService: Title,
  ) {
    this.title = config.appName;
  }

  ngOnInit() {
    this.titleService.setTitle(this.config.appName);
  }
}
