import {EventEmitter, Injectable, Output} from '@angular/core';
import {tap} from 'rxjs/operators';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AfidAssignmentRequest} from '../models/afid-assignment-request.model';
import {AfidAssignmentResponse} from '../models/afid-assignment-response';
import {NewAfidAssignmentRequest} from '../models/new-afid-assignment-request.model';


@Injectable({
  providedIn: 'root'
})
export class AfidAssignmentService {

  private resourceUrl = '/api/cascade/afid';


  /**
   * Notification to any subscribers of note save events
   * @type {EventEmitter<boolean>}
   */
  @Output() onProcessorAfidUpdateEvent = new EventEmitter<any>();


  constructor(private http: HttpClient) {}


  create(newAfidAssignmentRequest: NewAfidAssignmentRequest) {
    const headers = new HttpHeaders()
        .set("Content-Type", "application/json");

    return this.http.post<AfidAssignmentResponse>(this.resourceUrl, newAfidAssignmentRequest, {headers})
        .pipe(
            tap(
                (response: any ) => {
                  this.onProcessorAfidUpdateEvent.emit(response);
                })
        );
  }


  update(afidAssignmentRequest: AfidAssignmentRequest) {
    const headers = new HttpHeaders()
        .set("Content-Type", "application/json");

    return this.http.post<AfidAssignmentResponse>(this.resourceUrl, afidAssignmentRequest, {headers})
        .pipe(
        tap(
            (response: any ) => {
              this.onProcessorAfidUpdateEvent.emit(response);
            })
    );
  }
}
