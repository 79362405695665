import {EventEmitter, Injectable, Output} from '@angular/core';
import {Processor} from '../models/processor.model';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ProcessorDetailService {

  private resourceUrl = '/api/processor/detail/';

  /**
   * Customer cache stores last 10 customers
   */
  public processor: Processor = null;

  /**
   * Observable for current fetch so we can return same observable when multiple requests fire
   */
  public requestObservable: Observable<Processor>;

  /**
   * Stores timestamp of last processors refresh
   */
  public cacheTimestamp: Date;

  /**
   * Cache lifetime in seconds
   * @type {number}
   */
  private cacheLife = 3600;

  /**
   * Notification to Array<Processor> subscribers of note save events
   * @type {EventEmitter<boolean>}
   */
  @Output() onDataUpdateEvent = new EventEmitter<Array<Processor>>();


  constructor(private http: HttpClient) {

  }

  get (processorId: number): Observable<Processor> {
    return this.http.get<Processor>(this.resourceUrl + processorId);
  }


  // /**
  //  * Return cached processors if valid, or request from server
  //  * @param force
  //  */
  // get(force?: boolean): Observable<Processor> {
  //
  //   // If force is true, remove roles from cache
  //   if (force === true || this.processor === null || this.isExpired()) {
  //     this.processor  = null;
  //
  //   } else {
  //     // return cached processors
  //     console.log("Getting Cache");   //TODO: REMOVE ME
  //     return of(this.processor);
  //   }
  //
  //   return this.request();
  // }
  //
  //
  // /**
  //  * Perform Request to server for processors
  //  */
  // private request(): Observable<Processor> {
  //
  //   if ( !this.requestObservable ) {
  //     console.log("Requesting Cascade Options");
  //     this.requestObservable = this.http
  //         .get<Processor>(this.resourceUrl)
  //         .pipe(
  //             tap(
  //                 (processor: Processor) => {
  //                   console.log("Setting Cascade Options");   //TODO: REMOVE ME
  //                   this.processor = processor;
  //                   this.cacheTimestamp = new Date();
  //                 }
  //             )
  //         );
  //     //.share();
  //   }
  //
  //   return this.requestObservable;
  // }
  //
  //
  // /**
  //  * Returns true if cached processors is older than cache life setting
  //  * @param userId
  //  * @returns {boolean}
  //  */
  // isExpired() {
  //   let isExpired = false;
  //   const currentDate = new Date();
  //   let diffSeconds = 0;
  //
  //   const diff = currentDate.getTime() - this.cacheTimestamp.getTime();
  //   diffSeconds = Math.floor(diff/1000);
  //
  //   if(diffSeconds > this.cacheLife){
  //     isExpired = true;
  //   }
  //
  //   console.log({isExpired: isExpired})   //TODO: REMOVE ME
  //   return isExpired;
  // }
}
