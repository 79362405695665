import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {share, tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RdrImportService {
  private isFetching = false;
  private accountsUpdatedObservable: Observable<any>;

  constructor(private http: HttpClient) {
  }



  public upload(data): Observable<any> {

    if (this.isFetching === false) {
      this.isFetching = true;

      this.accountsUpdatedObservable = this.http
        .post('/api/sticky-io/record-rdr-import', data)
        .pipe(
          share(),
          tap(() => this.isFetching = false)
        );
    }

    return this.accountsUpdatedObservable;
  }


}
