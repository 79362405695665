<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">Update Processing Class</h5>
  <button type="button" class="close clickable" data-dismiss="modal" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">

  <app-error></app-error>

  <!-- Rules -->
  <p>You have selected the following rules for processing change:</p>
  <ul class="list-inline">
    <li class="list-inline-item" *ngFor="let key of objectKeys(recycleRuleRequest.ruleCheckbox)"><span *ngIf="recycleRuleRequest.ruleCheckbox[key]">{{key}}</span> </li>
  </ul>

  <form>
    <!--<input type="hidden" name="afid" id="afid" [(ngModel)]="afid">-->

    <!-- Process -->
    <div class="mb-3 row">
      <label for="pc" class="col-sm-4 col-form-label">Processing Class</label>
      <div class="col-sm-8">
        <select id="pc"
                name="pc"
                class='form-control form-control-sm'
                [(ngModel)]="recycleRuleRequest.processClassId">
                <!--(change)="onSelectChange($event)"-->
          <option [value]="p.id" *ngFor="let p of processClasses">{{p.name}}</option>
        </select>
      </div>
    </div>

  </form>

</div>

<div class="modal-footer">
  <button type="button" class="btn btn-outline-primary" (click)="save()">Save</button>
  <button type="button" class="btn btn-outline-secondary" (click)="close()">Close</button>
</div>

