<div class="container">
  <div class="page-head">

    <h3>Sticky.io RDR Import</h3>
    <div>
      <a href="https://ratespecial.atlassian.net/wiki/spaces/UK/pages/2239922177/RDR+Import" target="_blank">
        <i class="fas fa-info-circle" title="Help Article"></i>
      </a>
    </div>
  </div>

  <div class="card rs-card rs-card-gold card-tabbed">
    <div class="card-body min-paginated-card-height">
      <form id="upload-form" class="form-horizontal">
        <div class="mb-3">
          <label for="csv" class="col-sm-2 control-label">CSV File:</label>
          <div class="col-sm-6">
            <input id="csv" type="file" name="csv" #fileInput [(ngModel)]="csvFile" required/>
          </div>
        </div>
        <div class="mb-3">
          <div class="col-sm-offset-2 col-sm-10">
            <button class="btn btn-primary" (click)="upload()">Upload</button>
          </div>
        </div>
      </form>

      <div class="card-body" *ngIf="result && result.success">

        <ng-container *ngIf="result.date_range">
          <strong>Order Date Range: </strong> {{result.date_range.min | date: 'MMM d, yyyy, h:mm:ss a z'}} to {{result.date_range.max | date: 'MMM d, yyyy, h:mm:ss a z'}}<br>
        </ng-container>
        <strong>RDR's: </strong> {{result.rdr_count}}<br>
        <strong>Processed: </strong> {{result.processed}}<br>
        <strong>Skipped: </strong> {{result.skipped}}<br>
        <strong>Users Not Found: </strong> {{result.usersNotFound.length}}

        <div *ngIf="result.usersNotFound.length > 0" class="pt-3">
          <table class="table">
            <thead>
            <tr>
              <th scope="col">First</th>
              <th scope="col">Last</th>
              <th scope="col">Email</th>
              <th scope="col">Order Id</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let user of result.usersNotFound">
              <td>{{user.Bill_First}}</td>
              <td>{{user.Bill_Last}}</td>
              <td>{{user.Bill_Email}}</td>
              <td>{{user.Order_Id}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="card-body" style="color: #DD5858" *ngIf="httpErrorResponse">
        <strong>Error Occurred:</strong> {{httpErrorResponse.error.message}}
      </div>

    </div>

  </div>
</div>
