
<nav class="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
  <div class="container-fluid">
    <a class="navbar-brand" routerLink="/"><h2>UK Billing</h2></a>

    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse"
            aria-expanded="false" aria-label="Toggle navigation" (click)="toggleNavbar()">
      <span class="navbar-toggler-icon"></span>
    </button>


    <!--<button class="navbar-toggler navbar-toggler-right" type="button" (click)="navbarCollapsed = !navbarCollapsed"-->
            <!--[attr.aria-expanded]="!navbarCollapsed" aria-controls="navbarContent" aria-expanded="false" aria-label="Toggle navigation">-->
      <!--<span class="navbar-toggler-icon"></span>-->
    <!--</button>-->

    <!--<button class="navbar-toggler navbar-toggler-right" type="button" (click)="isNavbarCollapsed = !isNavbarCollapsed"-->
            <!--aria-controls="exCollapsingNavbar2" aria-expanded="false" aria-label="Toggle navigation">-->
      <!--<span class="navbar-toggler-icon"></span>-->
    <!--</button>-->

    <div class="collapse navbar-collapse" id="navbarCollapse" [ngbCollapse]="isNavbarCollapsed">

      <ul class="navbar-nav me-auto">
        <!--<li class="nav-item">-->
          <!--<a  class="nav-link" routerLink="/accounts">Accounts</a>-->
        <!--</li>-->

        <li class="nav-item" *ngIf="can('view-afid-cascade-assignment')">
          <a class="nav-link" routerLink="/billing-cascade/afid-assignment" routerLinkActive="active">Bill Cascade Assignments</a>
        </li>

        <li class="nav-item" *ngIf="can('view-processors')">
          <a  class="nav-link" routerLink="/processor" routerLinkActive="active">Processors</a>
        </li>

        <li class="nav-item" *ngIf="can('edit-signup-caps')">
          <a  class="nav-link" routerLink="/round-robin" routerLinkActive="active">Round Robin Billing</a>
        </li>

        <li class="nav-item" *ngIf="can('view-campaign-price')">
          <a  class="nav-link" routerLink="/campaigns" routerLinkActive="active">Campaigns</a>
        </li>

        <li class="nav-item" *ngIf="can('update-accounts')">
          <a  class="nav-link" routerLink="/account-updater" routerLinkActive="active">Account Updater</a>
        </li>

        <li class="nav-item" *ngIf="can('rdr-import')">
          <a  class="nav-link" routerLink="/rdr-import" routerLinkActive="active">RDR Import</a>
        </li>
      </ul>

      <ul class="navbar-nav">
        <li class="dropdown" ngbDropdown>
          <a class="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
            <i class="fa fa-lg fa-user-circle-o" aria-hidden="true"></i> {{user?.name}}
          </a>
          <ul class="dropdown-menu" ngbDropdownMenu>
            <li><a (click)="logout()" class="dropdown-item" ngbDropdownItem>Logout</a></li>
          </ul>
        </li>
      </ul>

    </div>
  </div>
</nav>
