import {Component, OnInit} from '@angular/core';
import {ProductInterface} from '../models/product.interface';
import {ProductService} from '../services/product.service';
import {AfidAssignmentService} from '../services/afid-assignment.service';
import { NgbModal, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import {forkJoin} from 'rxjs';
import {StatsService} from '../services/stats.service';
import {AfidAssignmentModalComponent} from '../afid-assignment-modal/afid-assignment-modal.component';
import {AddAfidModalComponent} from '../add-afid-modal/add-afid-modal.component';
import {UserService} from '../../../../shared/auth/services/user.service';
import { NgIf, NgFor, NgClass, DecimalPipe } from '@angular/common';


@Component({
    selector: 'app-afid-assignment-list',
    templateUrl: './afid-assignment-list.component.html',
    styleUrls: ['./afid-assignment-list.component.scss'],
    imports: [NgIf, NgbTooltip, NgFor, NgClass, DecimalPipe]
})
export class AfidAssignmentListComponent implements OnInit {

  public selectedTab = '';
  public currentProduct = 0;
  public products: Array<ProductInterface> = [];
  public stats: object;


  constructor(
    private productService: ProductService,
    private statsService: StatsService,
    private afidAssignmentService: AfidAssignmentService,
    private modalService: NgbModal,
    public userService: UserService,
  ) {
  }

  /**
   * Fetch Products and Stats
   */
  ngOnInit() {
    this.refreshList(true);

    this.afidAssignmentService
      .onProcessorAfidUpdateEvent
      .subscribe(event => this.refreshList(true));
  }


  /**
   * Retrieve the AFID Processor Assignment List w/ Stats
   * @param isForce Refetch product listing
   */
  refreshList(isForce = false) {
    forkJoin([
      this.productService.get(isForce),
      this.statsService.get()
    ]).subscribe(([products, stats]) => {
      this.products = products;
      this.selectedTab = products[this.currentProduct].product_name;
      this.stats = stats;
    });
  }

  openAfidAssignmentModal(afid, cascade_id) {
    // const modalRef = this.modalFactory.open(AfidAssignmentModalComponent);
    const modalRef = this.modalService.open(AfidAssignmentModalComponent);
    modalRef.componentInstance.selectedTab = this.selectedTab;
    modalRef.componentInstance.afid = afid;
    modalRef.componentInstance.cascade_id = cascade_id;

    // This will prevent an "uncaught in promise" error if it's dismissed
    modalRef.result.catch(() => {});
  }

  openAddAfidModal() {
    const modalRef = this.modalService.open(AddAfidModalComponent);
    modalRef.componentInstance.selectedTab = this.selectedTab;
  }

  switchList(index) {
    this.currentProduct = index;
    this.selectedTab = this.products[index].product_name;
  }
}
