import {Component, OnInit, Input} from '@angular/core';
import {Ability} from '../../models/ability.model';
import {AbilityService} from '../../services/ability.service';
import {Role} from '../../models/role.model';
import {AbilityUpdateRequest} from '../../models/ability-update-request.model';
import {RoleService} from '../../services/role.service';
import {AlertService} from "@ratespecial/core";
import { NgIf, NgFor, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';

@Component({
    selector: 'app-acl-selector',
    templateUrl: './acl-selector.component.html',
    styleUrls: ['./acl-selector.component.css'],
    imports: [NgIf, NgFor, NgSwitch, NgSwitchCase, NgSwitchDefault]
})
export class AclSelectorComponent implements OnInit {

    @Input()
    public role: Role;

    @Input()
    public isVisible: false;

    /**
     * Available Abilities
     * @type {any[]}
     */
    public abilities: Array<Ability> = [];

    /**
     * Form values
     * @type {{}}
     */
    public selectedAbilities = {};

    /**
     * Shadow copy for form value change detection and resetting
     * @type {{}}
     */
    public selectedAbilitiesShadow = {};


    constructor(
        private abilityService: AbilityService,
        private roleService: RoleService,
        private alertService: AlertService
    ) {}


    ngOnInit() {
        this.getAllAbilities();
    }


    /**
     * Get list of all abilities
     */
    getAllAbilities() {
        this.abilityService.get().subscribe(data => {
            this.abilities = data;

            this.abilities.map((ability: Ability) => {
                let isSelected = (this.role.abilities.indexOf(ability.name) > -1) ? true : false;
                this.selectedAbilities[ability.name] = isSelected;
                this.selectedAbilitiesShadow[ability.name] = isSelected;
            });
        });
    }


    /**
     * Handler for checkbox changes
     * @param name
     */
    onSelectionChange(name) {
        this.selectedAbilities[name] = !this.selectedAbilities[name];
    }


    /**
     * Return true if ability is selected
     * @param name
     * @returns {boolean}
     */
    hasAbility(abilityName) {
        return this.selectedAbilities[abilityName]
    }


    /**
     * Check if changes were made
     * @returns {boolean}
     */
    isDirty() {
        let isDirty = false;
        let abilityNames = Object.getOwnPropertyNames(this.selectedAbilities);

        for (var i = 0; i < abilityNames.length; i++) {
            if (this.selectedAbilities[abilityNames[i]] !== this.selectedAbilitiesShadow[abilityNames[i]]) {
                isDirty = true;
                break;
            }
        }

        return isDirty;
    }


    /**
     *
     */
    save() {
        let abilities = [];

        let abilityNames = Object.getOwnPropertyNames(this.selectedAbilities);

        for (var i = 0; i < abilityNames.length; i++) {
            if(this.selectedAbilities[abilityNames[i]] == true) {
                abilities.push(abilityNames[i]);
            }
        }

        const abilityUpdateRequest: AbilityUpdateRequest = {
            abilities: abilities
        };

        this.roleService.saveAbilities(this.role.id, abilityUpdateRequest).subscribe((role: Role) => {
            this.alertService.success('Abilities for Role ' + this.role.name + ' have been updated');
        });
    }


    /**
     *
     */
    reset() {
        let abilityNames = Object.getOwnPropertyNames(this.selectedAbilities);

        for (var i = 0; i < abilityNames.length; i++) {
            this.selectedAbilities[abilityNames[i]] = this.selectedAbilitiesShadow[abilityNames[i]]
        }
    }
}
