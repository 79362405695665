<div class="container">
  <div class="page-head">
    <div class="toolbar float-right">
      <i class="fa fa-lg fa-save clickable"
         (click)="save()"
         ngbTooltip="Save Changes">
      </i>
    </div>

    <h3 class="">Processor Round Robin Signup Cap</h3>
  </div>

  <div class="card rs-card rs-card-gold card-tabbed">
    <div class="card-body min-paginated-card-height">

      <div *ngFor="let prod of products | keyvalue">
        <h4>{{prod.value.name}}</h4>

        <table class="margin-top-15 table table-sm rs-table table-striped">
          <thead>
          <tr>
            <th class="text-center" style="width: 5%">ID</th>
            <th style="width: 30%">Name</th>
            <th>Signup Cap</th>
          </tr>
          </thead>

          <tbody>
          <tr *ngFor="let p of prod.value.processors">
            <td class="text-center">{{p.pid}}</td>
            <td>{{p.processor_name}}</td>
            <td><input type="text" [(ngModel)]="p.rr_cap"></td>
          </tr>
          </tbody>

          <tfoot>
          <tr>
            <td>Totals</td>
            <td>&nbsp;</td>
            <td>{{capTotalForProduct(prod.value.id)}}</td>
          </tr>
          </tfoot>
        </table>
      </div>
    </div>

  </div>
</div>
