import {Component, OnInit} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {CascadeOptions} from '../models/cascade-options.interface';
import {CascadeOptionsService} from '../services/cascade-options.service';
import {AfidAssignmentRequest} from '../models/afid-assignment-request.model';
import {AfidAssignmentService} from '../services/afid-assignment.service';
import {AfidAssignmentResponse} from '../models/afid-assignment-response';
import {ErrorService} from '../../../../shared/error/error.service';
import {AlertService} from "@ratespecial/core";
import { NgFor } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ErrorComponent } from '../../../../shared/error/error.component';

@Component({
    selector: 'app-afid-assignment-modal',
    templateUrl: './afid-assignment-modal.component.html',
    styleUrls: ['./afid-assignment-modal.component.scss'],
    imports: [ErrorComponent, FormsModule, NgFor]
})
export class AfidAssignmentModalComponent implements OnInit {

  /**
   * Only show Cascade Options for whichever Product Tab User is on
   */
  public selectedTab: string;

  /**
   * Initial AFID
   */
  public afid: number;

  /**
   * Initial Cascade ID
   */
  public cascade_id: number;

  /**
   * Form validation errors
   */
  public errors: any;

  /**
   * Form Select Options
   */
  public cascadeOptions: Array<CascadeOptions>;

  /**
   * Form Model
   */
  public assignment: AfidAssignmentRequest = {
    afid: 0,
    cascade_id: 0
  };


  constructor(
    private cascadeOptionsService: CascadeOptionsService,
    private afidAssignmentService: AfidAssignmentService,
    private alertService: AlertService,
    private activeModal: NgbActiveModal,
    private errorService: ErrorService
  ) {
  }


  ngOnInit() {
    this.errorService.clearErrors();

    this.assignment.afid = this.afid;
    this.assignment.cascade_id = this.cascade_id;

    this.cascadeOptionsService
      .get()
      .subscribe(cascadeOptions => {
        this.cascadeOptions = cascadeOptions[this.selectedTab];
      });
  }


  save() {
    this.errorService.clearErrors();

    this.afidAssignmentService
      .update(this.assignment)
      .subscribe((response: AfidAssignmentResponse) => {
          let message = '';

          if (response.afid) {
            message = 'AFID ' + response.afid + ' Assigned to Cascade: ' + response.cascade_id;
          } else {
            message = 'Default AFID Assigned to Cascade: ' + response.cascade_id;
          }

          this.alertService.success(message);
          this.close();
        },
        (err: HttpErrorResponse) => {
          this.errorService.handleValidationErrors(err);
        }
      );
  }


  close() {
    this.activeModal.close();
  }
}
