<div class="container" *ngIf="hasLoaded">

  <div class="page-head">
    <h3 class="">{{processor?.processor_name}}</h3>
  </div>

  <strong>Product: </strong>{{processor?.product?.product_name}}<br>
  <strong>Domain: </strong>{{processor?.product?.domain}}<br>

  <h4 class="mt-5">Processor Rules</h4>
  <div class="card rs-card rs-card-gold card-tabbed">
    <div class="card-body min-paginated-card-height">

      <div class="clearfix">
        <button
          type="button"
          class="btn btn-outline-primary float-right"
          (click)="openProcessClassModal()"
          ngbTooltip="Change Processing"
          container="body"
          *ngIf="userService.hasAbility('edit-processors')">

          <i class="fa fa-lg fa-random"></i>
        </button>
      </div>

      <table class="margin-top-15 table table-sm rs-table table-striped" *ngIf="processor && recycleRules">
        <thead class="">
        <tr>
          <th class="text-center">ID</th>
          <th class="">Event</th>
          <th class="text-center">Message Id</th>

          <th class="text-center">Response Code</th>
          <th class="">Response Message</th>

          <th class="text-center">Process</th>
          <th class="text-center">Apply To</th>

          <!--<th class="text-center">Actions</th>-->
        </tr>
        </thead>
        <tbody>
        <!--<tr *ngFor="let key of objectKeys(recycleRules)">-->
        <tr *ngFor="let r of recycleRules">
          <td class="text-center">{{r.id}}</td>
          <td class="">{{r.event_type}}</td>
          <td class="text-center">{{r.proc_response_id}}</td>

          <td class="text-center">{{r.processor_message.proc_resp_code}}</td>
          <td class="">{{r.processor_message.proc_message}}</td>

          <td class="text-center">{{r.process_class}}</td>

          <td class="text-center"><input type="checkbox" [(ngModel)]="ruleCheckbox[r.id]" /></td>
        </tr>
        </tbody>
      </table>

    </div>
  </div>


  <h4 class="mt-5">Processor Messages</h4>
  <div class="card rs-card rs-card-gold card-tabbed">
    <div class="card-body min-paginated-card-height">

      <table class="margin-top-15 table table-sm rs-table table-striped" *ngIf="processorMessages">
        <thead class="">
        <tr>
          <th class="text-center">MID</th>
          <th class="text-center">Response Code</th>
          <th class="">Response Message</th>
          <th class="">Max Tries</th>
          <th class="text-center">CID</th>
          <th class="text-center">Common Message</th>
          <th class="text-center" *ngIf="userService.hasAbility('edit-processors')">Actions</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let m of processorMessages">

          <td class="text-center">{{m.pm_id}}</td>
          <td class="text-center">{{m.proc_resp_code}}</td>
          <td class="">{{m.proc_message}}</td>
          <td class="">{{m.max_tries}}</td>
          <td class="text-center">{{m.cm_id}}</td>
          <td class="">{{m.common_message?.message}}</td>
          <td class="text-center" *ngIf="userService.hasAbility('edit-processors')">
              <i class="fas fa-exchange-alt clickable" (click)="openMessageMapModal(m)" ngbTooltip="Map Processor Message" container="body"></i>
          </td>
        </tr>
        </tbody>
      </table>

    </div>
  </div>
</div>
