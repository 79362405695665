import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {RdrImportService} from '../../services/rdr-import.service';
import {RdrImportResult} from '../../models/rdr-import-result';
import {HttpErrorResponse} from '@angular/common/http';
import {AlertService} from "@ratespecial/core";
import { NgIf, NgFor, DatePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-rdr-import-page',
    templateUrl: './rdr-import-page.component.html',
    styleUrls: ['./rdr-import-page.component.scss'],
    imports: [FormsModule, NgIf, NgFor, DatePipe]
})
export class RdrImportPageComponent implements OnInit {

  @ViewChild('fileInput') fileInput: ElementRef;
  public csvFile;
  public result: RdrImportResult;
  public httpErrorResponse: HttpErrorResponse;

  constructor(
    private rdrImportService: RdrImportService,
    private alertService: AlertService,
  ) { }

  ngOnInit(): void {
  }

  upload(): void {
    const fileBrowser = this.fileInput.nativeElement;

    if (fileBrowser.files && fileBrowser.files[0]) {
      this.httpErrorResponse = null;
      const form = new FormData();
      form.append('csv', fileBrowser.files[0]);

      this.rdrImportService
        .upload(form)
        .subscribe( result => {
          this.result = result;
          this.csvFile = null;
        }, error => {
          this.httpErrorResponse = error;
        });
    } else {
      this.alertService.error('No file selected');
    }

  }

}
