import {SubscriptionPackageDetailsModel} from 'src/app/modules/campaigns/models/subscription-package-details.model';

export class CampaignRequestModel extends SubscriptionPackageDetailsModel {
  cid: number | null = null;
  afid: number | null = null;
  broken_enrollment: boolean = false;
  cardless_auth: boolean = false;
  dine_membership: boolean = false;
  deferred_auth: false;
  darkweb_emails: number = 2;

  scheme_id: number;

  applyPackage(pkg: SubscriptionPackageDetailsModel): void {
    Object.assign(this, pkg);
  }
}
