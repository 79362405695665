<div class="login-bg">
  <div class="container">
    <div class="row justify-content-center">

      <div class="card rs-card rs-card-gold col-lg-6 col-md-8 mt-5">
        <div class="card-body">

          <h5 class="text-center">Log In</h5>

          <div class="mb-3 row justify-content-center">
            <div class="col-sm-12">
              <a class="mx-auto d-block" href="{{ googleAuthUrl }}"><img class="mx-auto d-block" src="/assets/Red-signin_Long_base_20dp.png"/></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
