<div class="container">
  <div class="page-head">

    <h3 class="">Sticky.io Account Updater</h3>
  </div>

  <div class="card rs-card rs-card-gold card-tabbed">
    <div class="card-body min-paginated-card-height">
      <form id="upload-form" class="form-horizontal">
        <div class="mb-3">
          <label for="csv" class="col-sm-2 control-label">CSV File:</label>
          <div class="col-sm-6">
            <input id="csv" type="file" name="csv" #fileInput [(ngModel)]="csvFile" required/>
          </div>
        </div>
        <div class="mb-3">
          <div class="col-sm-offset-2 col-sm-10">
            <button class="btn btn-primary" (click)="upload()">Upload</button>
          </div>
        </div>
      </form>

      <div class="card-body" *ngIf="result && result.success">
        <strong>Attempted: </strong> {{result.total_accounts}}
        <strong>Updated: </strong> {{result.accounts_updated}}
      </div>
      <div class="card-body" style="color: #DD5858" *ngIf="result && !result.success">
        <strong>Error Occurred:</strong> {{result.error}}
      </div>

    </div>

  </div>
</div>
