import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map, share, tap} from 'rxjs/operators';
import {AccountsUpdatedModel} from '../models/accounts-updated.model';

@Injectable({
  providedIn: 'root'
})
export class AccountUpdaterService {
  private isFetching = false;
  private accountsUpdatedObservable: Observable<AccountsUpdatedModel>;

  constructor(private http: HttpClient) {
  }



  public upload(data): Observable<AccountsUpdatedModel> {

    if (this.isFetching === false) {
      this.isFetching = true;

      this.accountsUpdatedObservable = this.http
        .post('/api/account-updater/sticky-io', data)
        .pipe(
          share(),
          map(
            resp => resp as AccountsUpdatedModel
          ),
          tap(
            resp => {
              this.isFetching = false;
            }
          )
        );
    }

    return this.accountsUpdatedObservable;
  }


}
