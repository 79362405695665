import {Component, OnInit} from '@angular/core';
import {RoleService} from '../services/role.service';
import {AbilityService} from '../services/ability.service';
import {Role} from '../models/role.model';
import {Ability} from '../models/ability.model';
import { AddAbilityComponent } from './add-ability/add-ability.component';
import { AclSelectorComponent } from './acl-selector/acl-selector.component';
import { NgIf, NgFor, NgClass } from '@angular/common';

@Component({
    selector: 'app-admin-acl',
    templateUrl: './acl.component.html',
    styleUrls: ['./acl.component.css'],
    imports: [NgIf, NgFor, NgClass, AclSelectorComponent, AddAbilityComponent]
})
export class AclComponent implements OnInit {

    /**
     * Available Abilities
     * @type {any[]}
     */
    public abilities: Array<Ability> = [];

    public selectedAbilities = {};

    /**
     * Available roles each with the abilities currently set
     * @type {any[]}
     */
    public roles: Array<Role> = [];

    /**
     * Since Role is an object, Rolemap maps roll names to the index of the object
     * for ease of processing
     * @type {Array}
     */
    public rolemap = [];


    public selectedRole = '';


    constructor(
        private roleService: RoleService
        //private abilityService: AbilityService
    ) {}

    ngOnInit() {
        this.getAllRoles();
        this.selectedRole = 'Admin';
    }


    /**
     * Get all available roles for checkbox options
     */
    getAllRoles() {
        this.roleService.get().subscribe(data => {
            this.roles = data;
            this.roles.sort(function(a, b){
                    if(a.name < b.name) return -1;
                    if(a.name > b.name) return 1;
                    return 0;
                });

            //Map rolename to id
            this.rolemap = this.roles.map(role => {
                return role.name
            });
        });
    }



    selectRole(rolename){
        this.selectedRole = rolename;
    }
}
