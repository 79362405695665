import {Component, Inject, OnInit} from '@angular/core';
import {APP_CONFIG} from '../../app.config';
import {IAppConfig} from '../../app-config.interface';
import {AuthService} from '../../shared/auth/services/auth.service';
import {UserService} from '../../shared/auth/services/user.service';
import { NgIf } from '@angular/common';
import { NgbCollapse, NgbDropdown, NgbDropdownToggle, NgbDropdownMenu, NgbDropdownItem } from '@ng-bootstrap/ng-bootstrap';
import { RouterLink, RouterLinkActive } from '@angular/router';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
    imports: [RouterLink, NgbCollapse, NgIf, RouterLinkActive, NgbDropdown, NgbDropdownToggle, NgbDropdownMenu, NgbDropdownItem]
})
export class NavbarComponent implements OnInit {

  public user: any;
  isNavbarCollapsed = true;
  /**
   * Flattened array of the ability names only
   */
  abilities: Array<string> = [];

  constructor(
    @Inject(APP_CONFIG) public config: IAppConfig,
    public userService: UserService,
    private authService: AuthService) {
  }

  ngOnInit() {
    this.userService
      .get()
      .subscribe(user => {
        this.user = user;

        user.abilities.forEach(ability => {
          this.abilities.push(ability.name);
        });
      });
  }

  logout() {
    this.authService.logout();
  }

  toggleNavbar() {
    this.isNavbarCollapsed = !this.isNavbarCollapsed;
  }

  /**
   * Checks user abilities if they can perform an action
   */
  can(ability: string): boolean {
    return this.abilities.indexOf(ability) > -1;
  }
}
