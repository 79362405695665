<div class="container">

  <div class="page-head">
    <h3 class="">AFID Assignment</h3>
  </div>

  <div class="toolbar">
    <i
      class="fa fa-lg fa-plus-circle clickable"
      (click)="openAddAfidModal()"
      ngbTooltip="Create New AFID Assignment"
      container="body"
      *ngIf="userService.hasAbility('edit-afid-cascade-assignment')">
    </i>
  </div>

  <ul class="nav nav-tabs">
    <li class="nav-item mb-1" *ngFor="let product of products; let i = index">
      <p class="nav-link d-inline cursor-pointer py-2 px-3"
         [ngClass]="{'active': selectedTab == product.product_name, ' text-primary': selectedTab != product.product_name}"
         (click)="switchList(i); false">{{product.product_name}}</p>
    </li>
  </ul>

  <div class="card rs-card rs-card-gold card-tabbed">
    <div class="card-body min-paginated-card-height">

      <p class="record-listing">{{products[currentProduct]?.billCascadeAssignments.length}} Records</p>

      <table class="margin-top-15 table table-sm rs-table table-striped" *ngIf="products[currentProduct]">
        <thead class="">
        <tr>
          <th>AFID</th>
          <th class="text-center">Cascade ID</th>
          <th>Processors</th>
          <th class="text-center">Signups</th>
          <th class="text-center">Daily Average</th>
          <th class="text-center">Actions</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let bca of products[currentProduct]?.billCascadeAssignments">
          <td>{{bca.afid}}</td>
          <td class="text-center">{{bca.cascade_id}}</td>
          <td class="">
            {{bca.processors_alt}}
            <!--<div class="row" *ngFor="let p of bca.processors">-->
            <!--<div class="col-4">{{p.processor_id}}</div>-->
            <!--<div class="col-4">{{p.processor_name}}</div>-->
            <!--<div class="col-4">{{p.processor_order}}</div>-->
            <!--
            {{stats[bca.afid].daily_avg}}
            {{stats[bca.afid].signups}}
            -->
            <!--</div>-->
          </td>
          <td class="text-center">{{stats[bca.afid]?.signups}}</td>
          <td class="text-center">{{stats[bca.afid]?.daily_avg | number: '1.1-1'}}</td>
          <td class="text-center">
            <i
              class="fas fa-pencil-alt clickable"
              (click)="openAfidAssignmentModal(bca.afid, bca.cascade_id)"
              *ngIf="userService.hasAbility('edit-afid-cascade-assignment')">
            </i>
          </td>
        </tr>
        </tbody>
      </table>

    </div>

  </div>

</div>
