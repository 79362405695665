import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {BillingSchemeModel} from '../models/billing-scheme.model';
import {map, share, tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BillingSchemeService {

  private isFetching = false;
  private schemesObservable: Observable<Array<BillingSchemeModel>>;
  private schemes: Array<BillingSchemeModel>;

  constructor(private http: HttpClient) {
  }

  public get(force: boolean = false): Observable<Array<BillingSchemeModel>> {

    if (force === true) {
      this.schemes = undefined;
    }

    // if we already have retrieved the user data from the server, return it
    if (this.schemes) {
      return of(this.schemes)
        .pipe(
          map(
            resp => resp as Array<BillingSchemeModel>
          )
        );
    }

    return this.fetch();
  }

  private fetch(): Observable<Array<BillingSchemeModel>> {

    if (this.isFetching === false) {
      this.isFetching = true;

      this.schemesObservable = this.http
        .get('/api/billing-schemes')
        .pipe(
          share(),
          map(
            resp => resp as Array<BillingSchemeModel>
          ),
          tap(
            resp => {
              this.isFetching = false;
              this.schemes = resp;
            }
          )
        );
    }

    return this.schemesObservable;
  }
}
