import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from 'src/app/shared/auth/services/auth.service';
import {UserService} from 'src/app/shared/auth/services/user.service';
import {HttpErrorService} from 'src/app/shared/http/http-error.service';
import {HistoryService} from 'src/app/shared/auth/services/history.service';
import {LoadIndicatorComponent, LoadIndicatorService} from '@ratespecial/core';


@Component({
    selector: 'app-google',
    templateUrl: 'google.component.html',
    styleUrls: ['google.component.css'],
    providers: [LoadIndicatorService],
    imports: [LoadIndicatorComponent]
})
export class GoogleComponent implements OnInit {

  constructor(private authService: AuthService,
              private route: ActivatedRoute,
              private router: Router,
              private loadIndicatorService: LoadIndicatorService,
              private userService: UserService,
              private httpErrorService: HttpErrorService,
              private historyService: HistoryService) {
  }

  ngOnInit() {

    if (this.authService.isAuthenticated() === false) {
      // NOTE: this component provides its own LoadIndicatorService, so this is separate from the root one.
      this.loadIndicatorService.push('google-auth');

      const googleCode = this.route.snapshot.queryParams['code'];

      this
        .authService.authenticateGoogleCode(googleCode)
        .subscribe({
          next: resp => {
            this.getUser();
            this.gotoLandingPage();
          },
          error: err => {
            this.httpErrorService.handleError(err);
            this.router.navigateByUrl('/auth/login');
          }
        });
    } else {
      // this.router.navigateByUrl('/');
    }
  }

  getUser() {
    this.userService.get(true);
  }

  gotoLandingPage() {
    const lastVisitedRoute = this.historyService.getLastVisitedRoute();

    if (lastVisitedRoute) {
      this.router.navigateByUrl(lastVisitedRoute);
    } else {
      this.router.navigateByUrl('/');
    }
  }
}
