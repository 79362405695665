import {EventEmitter, Injectable, Output} from '@angular/core';
import {Observable, of} from 'rxjs';
import {tap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class StatsService {

  private resourceUrl = '/api/stats/all';

  /**
   * Customer cache stores last 10 customers
   */
  public data: object = null;

  /**
   * Observable for current fetch so we can return same observable when multiple requests fire
   */
  public requestObservable: Observable<object>;

  /**
   * Stores timestamp of last data refresh
   */
  public cacheTimestamp: Date;

  /**
   * Cache lifetime in seconds
   * @type {number}
   */
  private cacheLife = 3600;

  /**
   * Notification to any subscribers of note save events
   * @type {EventEmitter<boolean>}
   */
  @Output() onStatsUpdateEvent = new EventEmitter<any>();


  constructor(private http: HttpClient) {
    this.cacheTimestamp = new Date();
  }


  /**
   * Return cached data if valid, or request from server
   * @param force
   */
  get(force?: boolean): Observable<any> {

    // If force is true, remove roles from cache
    if (force === true || this.data === null || this.isExpired()) {
      this.data  = null;

    } else {
      // return cached data
      return of(this.data);
    }

    return this.request();
  }


  /**
   * Perform Request to server for data
   */
  private request(): Observable<any> {

    if ( !this.requestObservable ) {
      this.requestObservable = this.http
          .get<any>(this.resourceUrl)
          .pipe(
              tap(
                  data => {
                    this.data = data;
                    this.cacheTimestamp = new Date();
                  }
              )
          );
      //.share();
    }

    return this.requestObservable;
  }


  /**
   * Returns true if cached data is older than cache life setting
   * @param userId
   * @returns {boolean}
   */
  isExpired() {
    let isExpired = false;
    const currentDate = new Date();
    let diffSeconds = 0;

    const diff = currentDate.getTime() - this.cacheTimestamp.getTime();
    diffSeconds = Math.floor(diff/1000);

    if(diffSeconds > this.cacheLife){
      isExpired = true;
    }

    return isExpired;
  }
}
