import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {Role} from '../../models/role.model';
import {RoleService} from '../../services/role.service';
import {UserAclService} from '../../services/user-acl.service';
import {User} from '../../../shared/models/user.model';
import {RoleUpdateRequest} from '../../models/role-update-request.model';
import {HttpErrorResponse} from '@angular/common/http';
import {ErrorService} from '../../../shared/error/error.service';
import {AlertService} from "@ratespecial/core";
import { NgIf, NgFor, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';

@Component({
    selector: 'app-role-selector',
    templateUrl: './role-selector.component.html',
    styleUrls: ['./role-selector.component.css'],
    imports: [NgIf, NgFor, NgSwitch, NgSwitchCase, NgSwitchDefault]
})
export class RoleSelectorComponent implements OnInit {

    /**
     * Current user id
     * @type {number}
     */
    public userId: number = 0;

    /**
     * Available Roles
     * @type {any[]}
     */
    public roles: Array<Role> = [];


    //@Input() public user: User;

    /**
     * Form Roles
     * @type {any[]}
     */
    public selectedRoles = [];

    /**
     * User's Current Roles
     * @type {any[]}
     */
    public userRoles = [];


    constructor(
        private route: ActivatedRoute,
        private roleService: RoleService,
        private userAclService: UserAclService,
        private alertService: AlertService,
        private errorService: ErrorService
    ) {}


    ngOnInit() {
        this.userId = this.route.snapshot.params['id'];

        this.getAllRoles();
        this.loadUserRoles();

        //Subscribe to user update events
        this.userAclService.onUserStateChangeEvent
            .subscribe( (event) => {
                if (event === 'abilities') {
                    this.loadUserRoles()
                }
            });
    }


    /**
     * Get all available roels for checkbox options
     */
    getAllRoles() {
        this.roleService.get().subscribe(data => {
            this.roles = data;
        });
    }


    /**
     * Load user's current roles
     * @param id
     */
    loadUserRoles() {
        this.userAclService.get(this.userId).subscribe(
            (user: User) => {
                this.selectedRoles = [];
                this.userRoles = user.roles;
                user.roles.map( role => {
                    this.selectedRoles.push(role);
                });
            }
        );
    }


    /**
     * Handler for checkbox state
     * @param name
     * @returns {boolean | boolean}
     */
    hasRole(name) {
        return (this.selectedRoles.indexOf(name) > -1) ? true : false;
    }


    /**
     * Handler for checkbox changes
     * @param name
     */
    onSelectionChange(name) {

        const index = this.selectedRoles.indexOf(name);

        if (index > -1) {
            this.selectedRoles.splice(index, 1);
        } else {
            this.selectedRoles.push(name);
        }
    }


    /**
     * Check if changes were made
     * @returns {boolean}
     */
    isDirty() {
        let isDirty = false;

        if(this.selectedRoles.length === this.userRoles.length) {
            this.selectedRoles.sort();
            this.userRoles.sort();

            for (var i = 0; i < this.selectedRoles.length; i++) {
                if(this.selectedRoles[i] !== this.userRoles[i]){
                    isDirty = true;
                    break;
                }
            }
        }else{
            isDirty = true;
        }

        this.userAclService.setDirtyState({name: 'roles', isDirty: isDirty});
        return isDirty;
    }


    /**
     * Save user role changes
     */
    save() {

        let roleUpdateRequest: RoleUpdateRequest = {
            roles: this.selectedRoles
        }

        this.userAclService.saveRoles(this.userId, roleUpdateRequest)
            .subscribe(
                (user: User) => {
                this.loadUserRoles();
                this.alertService.success('Roles have been updated');
                },
                (err: HttpErrorResponse) => {
                    this.errorService.handleValidationErrors(err);
                });
    }
}
