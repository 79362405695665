import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ProcessorService {

  constructor(private http: HttpClient) { }

  update(data) {
    return this.http
      .put('/api/processor/rr-caps', data);
  }
}
