import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {SubscriptionPackageDetailsModel} from 'src/app/modules/campaigns/models/subscription-package-details.model';

@Component({
    selector: 'app-package-selector',
    templateUrl: './package-selector.component.html',
    styleUrls: ['./package-selector.component.scss'],
    imports: [
        FormsModule,
    ]
})
export class PackageSelectorComponent implements OnInit {
  @Input() val = 'club-2024';
  @Output() packageChange = new EventEmitter<SubscriptionPackageDetailsModel>();

  onChange($event: string) {
    this.packageChange.emit(SubscriptionPackageDetailsModel.getPackageDetails($event));
  }

  ngOnInit() {
    if (this.val === '') {
      // this.val = 'club-2024';
      this.packageChange.emit(SubscriptionPackageDetailsModel.getPackageDetails(this.val));
    }
  }
}
